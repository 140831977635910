<!-- <p>user-general-information works {{item}}!</p> -->
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">View Contact</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
<div class="order-view  max-WT-700 mrgn-0-auto setting-page">
    <div class="mb40">
        <div class="add-store-block input-style view-label-align" style="width: 90%;">
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright "> Username</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.userName  || "--"}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row d-flex">
                <label class="col-md-4 textalignright ">Email</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.userEmail || '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Message</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.comment || '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Created Date & Time</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.createdAt| date:'medium'}}</p>
                    </div>
                </div>
            </div>
            <div class="text-center mt40 mb40">
                <button type="button" class="btn btn-large mr10 max-WT-150 btn-secondary" data-toggle="modal" data-target="#replyModal">Reply</button>
                <button type="button" class="btn btn-large  max-WT-150 btn-secondary"
                    [routerLink]="['/support']">Back</button>
            </div>
        </div>
    </div>

</div>
</div>
</main>
<!-- Middle Content End -->
</div>
<div class="modal fade" id="replyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content p5">
        <form action="" [formGroup]="replyForm">
            <div class="modal-body-content">
                <h1>Reply</h1>
            </div>
            <div class="modal-body">
              <input type="text" class="form-control" placeholder="Subject" formControlName="subject">
              <div class="validators">
                  <span *ngIf="replyForm.get('subject').invalid && (replyForm.get('subject').dirty || replyForm.get('subject').touched)">
                    *Subject is required.
                  </span>
              </div>
              <textarea name="" id="" cols="30" rows="10" class="form-control mt30" placeholder="Message" formControlName="message"></textarea>
              <div class="validators">
                <span *ngIf="replyForm.get('message').invalid && (replyForm.get('message').dirty || replyForm.get('message').touched)">
                  *Message is required.
                </span>
            </div>
            </div>
            <div class="modal-footer send-close">
                <button type="button" [disabled]="replyForm.invalid"> <a  class="btn btn-large  max-WT-150 btn-secondary" (click)="replyUser(this.replyForm.get('subject').value,this.replyForm.get('message').value)">Send</a></button>
                <!-- <button type="button" class="btn btn-secondary">Send</button> -->
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="replyForm.reset()">Close</button>
            </div>
        </form>
      </div>
    </div>
</div>