<!-- <p>user-general-information works {{item}}!</p> -->
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">View Report</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
<div class="order-view  max-WT-700 mrgn-0-auto setting-page">
    <div class="mb40">
        <div class="add-store-block input-style view-label-align" style="width: 90%;">
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright "> Reported by</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p>
                            {{(userData?.fromEmail  | titlecase ) || "--"}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row d-flex">
                <label class="col-md-4 textalignright ">Post Created By	</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p >
                            {{(userData?.toEmail  | titlecase )|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Created Date & Time</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p >
                            {{userData?.createdAt| date:'medium'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row " *ngIf="userData?.postId?.mediaType=='IMAGE'">
                <label class="col-md-4 textalignright ">Posted Image</label><span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="row">
                        <img *ngIf="userData?.postId?.imageLinks[0]"  [src]="particularimg" class="particularImage" alt="img" >
                    </div>
                  
                    <div class="row">
                        <img *ngFor="let image of userData?.postId?.imageLinks" class="col-md-4 postedImage"  [ngClass]="{'active':image == particularimg}" (click)="select(image)" [src]="image" alt="img" >
                    </div>
                     <img *ngIf="!userData?.postId?.imageLinks.length" class="postedImage" src="assets/img/no_img.png" alt="img" >
                </div>
            </div>

            <div *ngIf="userData?.postId?.mediaType=='VIDEO'" class="form-group row " >
                <label class="col-md-4">Posted Video</label>
                <label class="col-md-1"> : </label>
                <label class="col-md-5" >
                    <video width="290" controls>
                        <source [src]="userData?.postId?.videoLink" type="video/mp4">
                      </video>
               </label>
            </div>
  
            <div *ngIf="userData?.commentId?.comment" class="form-group row ">
                <label class="col-md-4 textalignright ">Reported Comment</label><span class="col-md-1">:</span>
                <label class="col-md-7 textalignright;padding: 0px 50px!important" style="word-break: break-word;">
                    <!-- <div class="form-group"> -->
                        <!-- <p style="padding: 0px 50px!important;word-break: break-all;"> -->
                            {{(userData?.commentId?.comment | titlecase) || '--'}}
                    <!-- </div> -->
                </label>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Reason</label><span class="col-md-1">:</span>
                <label class="col-md-7 textalignright;padding: 0px 50px!important" style="word-break: break-word;">
                    <!-- <div class="form-group"> -->
                        <!-- <p style="padding: 0px 50px!important;word-break: break-all;"> -->
                            {{(userData?.description | titlecase) || '--'}}
                    <!-- </div> -->
                </label>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Report Type</label> <span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p >
                            {{(userData?.reportType | titlecase)||'--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Report Status</label> <span class="col-md-1">:</span>
                <div class="col-md-7 textalignright">
                    <div class="form-group">
                        <p >
                            {{(userData?.responseStatus | titlecase)||'--'}}</p>
                    </div>
                </div>
            </div>
 
            <div class="text-center mt40 mb40">
                <button type="button" class="btn btn-large  max-WT-150 btn-secondary"
                    [routerLink]="['/report-management']">Back</button>
                <button type="button" class="btn btn-large ml20 max-WT-150 btn-secondary" (click)="getUserId(userData?._id,userData?.responseStatus)"
                >{{(userData?.blockStatus)== true ? 'Unblock' :'Block' }}</button>
            </div>
        </div>
    </div>

</div>
</div>
</main>
<!-- Middle Content End -->
</div>



<div class="modal fade global-modal reset-modal" id="ReportModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog max-WT-500">
    <form  [formGroup]="searchForm" class="change_password">
        <div class="modal-content">
            <div class="modal-header d-block text-center modal-header-custm" style="border-bottom: none;">
                <h5 class="modal-title d-inline-block">Reason for Report this post?</h5>
            </div>
            <div class="modal-inner-content">
                <div class="modal-body">
                    <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                        <div class="w-100">
                            <textarea class="form-control"  cols="30" rows="5"  formControlName="report"  placeholder="Description..."
                            (keypress)="service.preventSpace($event)" ></textarea> 
                            <!-- <p>Are you sure you want to {{statusString | lowercase}} this user?</p> -->
                            <div class="modal-btn-box" style="    margin-top: 15px;">
                                <button type="submit" class="btn btn-primary mr-2" data-dismiss="modal" (click)="searchForm.reset()">Close</button>
                                <button type="submit" class="btn btn-primary" data-dismiss="modal"  [disabled]="searchForm.invalid" (click)="blockUnblockPost()" >Submit</button>
                                <!-- <button type="button" class="btn btn-secondary  ml-2" data-dismiss="modal">No</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
    </div>