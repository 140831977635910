<!-- <p>user-general-information works {{item}}!</p> -->
<div class="order-view  max-WT-700 mrgn-0-auto setting-page">
    <div class="mb40">
        <div class="add-store-block input-style view-label-align" style="width: 90%;">
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright "> Name</label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.fullName || "--"}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright ">Username</label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.userName || "--"}}</p>
                    </div>
                </div>
            </div>

            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Email</label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.email   || '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Phone Number </label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.countryCode}} {{userData?.phoneNumber|| '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-baseline">
                <label class="col-md-4 textalignright ">Profile Type</label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.profileType || "--"}}</p>
                    </div>
                </div>
            </div>
  
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Created Date & Time</label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{(userData?.createdAt | date:'medium')|| '--'}}</p>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group row ">
                <label class="col-md-4 textalignright ">Social Links </label>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{userData?.phoneNumber|| '--'}}</p>
                    </div>
                </div>
            </div> -->
            <div class="form-group row ">
                <label class="col-md-4 textalignright ">Status </label><span class="col-md-1">:</span>
                <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                        <p style="padding: 0px 50px!important;">
                            {{(userData?.status | titlecase) || '--'}}</p>
                    </div>
                </div>
            </div>
            <div class="text-center mt40 mb40">
                <button type="button" class="btn btn-large  max-WT-150 btn-secondary"
                    [routerLink]="['/user-list']">Back</button>
            </div>
        </div>
    </div>

</div>
