
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">View post</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style" >
                        <div class="form-group row ">
                            <label class="col-md-4" >Post Id</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?._id}} </label>
                        </div>

                        <div *ngIf="viewParticularPost?.mediaType=='IMAGE'" class="form-group row ">
                            <label class="col-md-4">Posted Image</label>
                            <label class="col-md-1"> : </label>
                            <label  class="col-md-6" >
                                <div class="row">
                                    <img *ngIf="viewParticularPost.imageLinks[0]"  [src]="particularimg" class="particularImage" alt="img" >
                                </div>
                              
                                <div class="row">
                                    <img *ngFor="let image of viewParticularPost?.imageLinks" class="col-md-4 postedImage"  [ngClass]="{'active':image == particularimg}" (click)="select(image)" [src]="image" alt="img" >
                                </div>
                                 <img *ngIf="!viewParticularPost?.imageLinks.length" class="postedImage" src="assets/img/no_img.png" alt="img" >
                            </label>
                        </div>
                        <div *ngIf="viewParticularPost?.mediaType=='VIDEO'" class="form-group row " >
                            <label class="col-md-4">Posted Video</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-6" >
                                <video width="320" height="240" controls>
                                    <source [src]="viewParticularPost?.videoLink" type="video/mp4">
                                </video>
                           </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Media Type</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{(viewParticularPost?.mediaType | titlecase)||'--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Posted Address</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.address || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Title</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.title || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7" style="height: 10vh;overflow: auto;"> {{viewParticularPost?.description || '--'}} </label>
                        </div>
                      
                        <div class="form-group row ">
                            <label class="col-md-4">Status</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{(viewParticularPost?.status | titlecase) || "--"}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4" >Created Date & Time</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.createdAt | date:'medium'}}</label>
                        </div>
               
                        <div class="text-center mt40">
                            <button class="btn btn-large ml20 max-WT-150  btn-secondary ml5" style=" width: 100px;" (click)="listpost()">Back</button>
                            <button type="button" class="btn btn-large ml20 max-WT-150 btn-secondary" style=" width: 100px;"data-toggle="modal" data-target="#BlockUnblockModal"
                                >{{(viewParticularPost?.status)=='BLOCKED' ? 'Unblock' :'Block' }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>


<!-- block-unblock modal -->
<div class="modal fade global-modal reset-modal" id="BlockUnblockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 *ngIf='viewParticularPost?.status=="ACTIVE" ' class="modal-title d-inline-block">Block</h5>
                    <h5 *ngIf='viewParticularPost?.status=="BLOCKED" ' class="modal-title d-inline-block">Unblock</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf='viewParticularPost?.status=="ACTIVE" ' >Are you sure you want to Block this Post?</p>
                                <p *ngIf='viewParticularPost?.status=="BLOCKED" '>Are you sure you want to Unbock this Post?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="blockUnblockUser()">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>