<p>static-content works!</p>
<!-- <p>user-list works! {{item}}</p> -->
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Static Content</h1>
    </div>
    <div class="content-section">
        <!-- <div class="col-md-2 float-right mb-2">
            <button class="btn btn-primary" routerLink="/add-static">ADD NEW</button>
        </div> -->
    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">
                        <div class="table-responsive" style="margin-bottom: 60px;">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                <thead class="thead">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img heading1">S.No</th>
                                        <th scope="col" class="Sorting-img">Page Name</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img heading2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let item of staticContentList let i = index">
                                        <td>{{i+1}}</td>
                                        <td class="content">{{item?.title | custom}}</td>
                                        <td class="justify-content-center d-flex">
                                            <button  class="btn btn-info ml-2 bt-margin bth" title="View" (click)="staticView(item.type,item?._id)">
                                                <em class="fa fa-eye" aria-hidden="true"></em>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="!staticContentList?.length">
                                        <td colspan="4">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
            </div>
        </div>
    </div>
</div>
</main>
