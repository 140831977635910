<!-- <p>user-followings works!  {{UserPostList}}</p> -->
<main >

    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">

                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">

                                <form [formGroup]="searchForm">
                                    <div class="row align-items-center">
                                        <!-- <div class="col-md-3 pr-0">
                                            <div class="filter_search mb20 width100">
                                                <label class="d-flex align-items-center">Search:</label>
                                                <div class="input-group filter_search_group">
                                                    <input type="text" class="form-control overflow-text" formControlName="search" placeholder="Search by email" maxlength="60">
                                                    <div class="input-group-append">
                                                        <button class="btn btn_search_group"><img src="assets/img/icon-search.png" (click)="search()"  alt="Search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-3 pr-0">
                                            <label class="d-flex align-items-center">From Date:</label>
                                            <input type="date" class="form-control mb20" formControlName="fromDate" [max]="today"> 
                                        </div>
                                        <div class="col-md-3 pr-0">
                                            <label class="d-flex align-items-center">To Date:</label>
                                            <input type="date" formControlName="toDate" [readonly] = "!this.searchForm.controls['fromDate'].value" [min]="this.searchForm.controls['fromDate'].value" class="form-control mb20">
                                        </div>
                                        <div class="col-md-5">
                                            <div class="text-center admin-btn mb2 mt10">
                                                <button type="button" class="btn btn-theme" (click)="search()">Search</button>
                                                <button type="button" class="btn btn-theme" (click)="reset()">Reset</button>
                                                <!-- <button type="button" class="btn btn-theme btn-export" (click)="exportAsXLSX()">EXPORT EXCEL</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive" style="margin-bottom: 60px;">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                <thead class="thead">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img heading1">S.No</th>
                                        <th scope="col" class="Sorting-img">Name</th>
                                        <th scope="col" class="Sorting-img">Username</th>
                                        <th scope="col" class="Sorting-img">Created Date & Time</th>
                                        <th scope="col" class="Sorting-img">Request Status</th>
                                        <th scope="col" class="Sorting-img">Status</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img heading2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let item of customerData | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index">
                                        <td>{{i+1+(currentPage-1)*itemsPerPage}}</td>      
                                        <td class="content">{{item?.userId?.fullName || '--'}}</td>
                                        <td class="content">{{item?.userId?.userName || '--'}}</td>
                                        <td class="content">{{item.createdAt |date:'medium'}} </td>
                                        <td class="content">{{item.request || '--'}}</td>
                                        <td class="content">{{item.status || '--'}}</td>
                                        <td class="justify-content-center d-flex">
                                            <button class="btn btn-info ml-2 bt-margin bth" title="View" (click)="getFollowingId(item._id)" >
                                                <em class="fa fa-eye" aria-hidden="true"></em>
                                            </button>
                   
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="!customerData.length">
                                        <td colspan="7">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > itemsPerPage" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
            </div>
        </div>
    </div>
</main>