import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from './provider/common.service';
import {filter} from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  notFound:boolean
  constructor(public service:CommonService,private router:Router){
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      if(event.urlAfterRedirects=='/not-found'){
        this.notFound=false
      }
      else{
        this.notFound=true
      }
    });
}
title = 'street-strom-admin';
}