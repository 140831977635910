import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-static-view',
  templateUrl: './static-view.component.html',
  styleUrls: ['./static-view.component.css']
})
export class StaticViewComponent implements OnInit {
  id: any;
  about: any;
  data: any;
  title:any
  pagekey:any
  // header: any;
  constructor(public service:CommonService, public route: ActivatedRoute, private navigate:Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(x=>{
      this.pagekey= x['data'];
    })
    this.viewStaticContent();
  }

  // view static content for perticular title
  viewStaticContent(){
    this.service.showSpinner();
    let url = `static/viewStaticContent?type=${this.pagekey}`
    this.service.getApi(url,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.title = res.result.title
        this.data = res.result.description
        this.id = res.result._id
        // console.log(this.data);
        this.service.hideSpinner();
      }
    })
  }

  // update static content for perticular title
  updateStaticContent(){
    this.service.showSpinner();
    let description={
      "title": this.pagekey,
      "description": this.data
    }
    let url = `static/editStaticContent?_id=${this.id}`
    this.service.putApi(url,description,1 ).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.navigate.navigate(['/static'])
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    })
  }
}
