

<section class="vh-100" style="background-color: aliceblue;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col col-xl-10">
          <div class="card" style="border-radius: 1rem;">
            <div class="row g-0">
              <div class="col-md-6 col-lg-5 d-none d-md-block">
                <img
                  src="/assets/img/strom_street.jpg"
                  alt="login form"
                  class="img-fluid" style="border-radius: 1rem 0 0 1rem;"
                />
              </div>
              <div class="col-md-6 col-lg-7 d-flex align-items-center">
                <div class="card-body p-4 p-lg-5 text-black">
  
                  <form [formGroup]="forgetform">
  
                    <!-- <div class="align-items-center text-center mb-3 pb-1">
                      <span class="h1 fw-bold mb-0">ADMIN</span>
                    </div> -->
  
                    <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">FORGOT PASSWORD</h5>
  
                    <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example17" >Enter your registered email address. We will help you to reset your password.</label>
                      <input type="email" id="form2Example17" class="form-control"name="email" formControlName="email" placeholder="Enter your email address" maxlength="60"  />
                      <div *ngIf="forgetform.get('email').errors && (forgetform.get('email').touched || forgetform.get('email').dirty)" class="text-danger">
                        <span *ngIf="forgetform.get('email').hasError('pattern')"> *Please enter valid email address.</span>
                        <span *ngIf="forgetform.get('email').hasError('required')"> *Email address is required.</span>
                      </div>                  
                    </div>
                    <div class="form-outline mb-4 text-right">
                      <a class="link-resend" (click)="resendOtp()">Resend otp</a>                
                    </div>
                    <div class="pt-1 mb-4">
                      <button class="btn btn-dark btn-lg btn-block" [disabled]="!forgetform.valid"  (click)="verfyEmail()"  type="button">SUBMIT</button>
                    </div>
       
                  </form>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div class="modal" id="exampleModal" tabindex="1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"  id="exampleModalLabel" style="color: black;">Please Enter OTP</h5>

        </div>
        <div class="modal-body mb40 mt40" style="display: flex; justify-content: center; white-space: nowrap;">
          <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4,allowNumbersOnly:true}"  style="background:transparent ; color: #fff;" ></ng-otp-input>
        </div>
        <div class="modal-footer" >
          <button type="button" class="btn btn-dark btn-lg btn-block" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-dark btn-lg btn-block" (click)="verfyOtp()">Verify</button>
        </div>
      </div>
    </div>
  </div>
  
  
  
