import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  confirmPassword:boolean=true
  newPassword:boolean=true

  constructor(public service:CommonService, private router:Router,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    // form controls and validations
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.compose([Validators.required])),
      newPassword: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required])),
    })
  }

  back() {
    this.router.navigate(['/profile'])
  }

  // hit api on click change password
  changePassword() {
    var apiReqData = {
      'password': this.changePasswordForm.value.oldPassword,
      'newPassword': this.changePasswordForm.value.newPassword
    }
    console.log(apiReqData);
    let apiReqUrl="admin/changePassword"
    this.service.showSpinner();
    this.service.putApi(apiReqUrl, apiReqData,1).subscribe((res:any) => {

      if (res.responseCode == 200) {
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage);
        // this.service.onLogout()
        // this.router.navigate(['/login'])
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    })
  }
  
}
