<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">Add Banner</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">
                        <div class="form-group row ">
                            <label class="col-md-3">Banner Image</label>
                            <label class="col-md-1"> : </label>
                            <div class="col-md-6">
                                    <input type="file" class="form-control" placeholder="Category Name" (change)="uploadFile($event)">
                            </div>
                             <div class="col-md-12">
                                <label class="col-md-3" ></label>
                                <label class="col-md-1"> </label>
                                <div class="col-md-5 d-inline">
                                    <img class="mt-2" style="width: 250px;" [src]="bannerImage" alt="" >
                                </div>
                             </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-3">Banner Title</label>
                            <label class="col-md-1"> : </label>
                            <div class="col-md-6">
                                <form action="" [formGroup]="addBannerForm">
                                    <textarea class="form-control" rows="3" placeholder="Your message...." formControlName="bannerTitle"></textarea>
                                <div class="validations">
                                    <span *ngIf="(addBannerForm.controls['bannerTitle'].invalid && (addBannerForm.controls['bannerTitle'].dirty || addBannerForm.controls['bannerTitle'].touched))">
                                        *Please enter banner title.
                                    </span>
                                </div>
                                </form>
                            </div>
                        </div>

               
                        <div class="text-center mt40">
                            <button class="btn btn-theme  max-WT-200 font-100 btn-green mr-2" (click)="addBanner()">Submit</button>
                            <button class="btn btn-theme  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/banner-management']">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
