import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

declare var $;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  total: any;
  faqId: any;
  faq:any =[]

  constructor(private commonService:CommonService, private router:Router) { }

  ngOnInit(): void {
    this.getFaqList()
  }

  pagination(event) {
    this.currentPage = event;
    this.getFaqList()
  }

  // ------- get FAQ list -------- //
  getFaqList() {
    let url = "static/faqList"
    this.commonService.showSpinner();
    this.commonService.getApi(url,1).subscribe((res:any) => {
      if(res.responseCode == 200){
        this.faq = res.result;
        this.commonService.successToast(res.responseMessage)
        this.commonService.hideSpinner()
      }
      else{
        this.commonService.errorToast(res.responseMessage)
        this.commonService.hideSpinner()
      }
    })
}

addFaq() {
  this.router.navigate(['/add-faq'])
}
viewUser(id) {
  this.router.navigate(['view-faq'], { queryParams: { id: id } })
}
editFaq(id) {
  this.router.navigate(['/edit-faq'], { queryParams: { id: id } })
}
deleteFaqModal(id) {
  this.faqId = id
  $('#delete').modal('show')
}
deleteFaq(){
  let url = `static/deleteFaq?_id=${this.faqId}`
    this.commonService.deleteApi(url,{},1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        this.getFaqList()
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast("Data not found.")
      }
    })
}


}
