import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { category } from '../dummy';
declare var $:any
@Component({
  selector: 'app-user-post-view',
  templateUrl: './user-post-view.component.html',
  styleUrls: ['./user-post-view.component.css']
})
export class UserPostViewComponent implements OnInit {
  id: any;
  about: any;
  // dataa: any=[];
  userData: any;
  particularimg:any
  date=new Date()
  postId:any
  viewParticularPost:any;
  constructor(public service:CommonService, public route: ActivatedRoute, public router:Router) { }

  ngOnInit() {
    // this.userData=[...category]
    this.route.queryParams.subscribe(x=>{this.postId= x['id']; })
    this.viewPost()
  }
// view perticular category 
  viewPost(){
    this.service.showSpinner()
    let url =  `admin/viewUserPost?_id=${this.postId}`
    this.service.getApi(url,1).subscribe((res:any)=>{
     if(res.responseCode==200){
       this.viewParticularPost=res.result
       this.particularimg=this.viewParticularPost.imageLinks[0]
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
       this.service.hideSpinner()
       this.service.errorToast("data not found")
     }
    })
    }
    listpost(id,tab) {
      this.router.navigate(["/user-management"], { queryParams: {Id: id,tab:tab} });
    }


}
