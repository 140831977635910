import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { dummy } from '../dummy';

@Component({
  selector: 'app-user-general-information',
  templateUrl: './user-general-information.component.html',
  styleUrls: ['./user-general-information.component.css']
})
export class UserGeneralInformationComponent implements OnInit {
  @Input() item = ''; 
  userId: any;
  userData: any;

  constructor(private commmanService : CommonService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.userId = res.Id
    })
  }

  ngOnInit(): void {
    this.viewUserData()
  }

  viewUserData(){
    let apiReqUrl =`admin/viewUser?_id=${this.userId}`
    this.commmanService.showSpinner()
    this.commmanService.getApi(apiReqUrl,1).subscribe((res:any)=>{
      if (res.responseCode == 200) {
        this.userData = res.result
        this.commmanService.hideSpinner()
        this.commmanService.successToast(res.responseMessage)
      } else {
        this.commmanService.hideSpinner()
        this.commmanService.errorToast(res.responseMessage)
      }
    })
  }
}
