export const dummy = [{
    "id": "f7d4d603-6466-417f-a1d9-43e8bcfbb5a9",
    "S_No":1,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Miller, Zieme and Heathcote",
    "email":"miller@gmail.com",
    "hub_logo": "http://dummyimage.com/227x100.png/5fa2dd/ffffff",
    "created_by": "Doralyn Cullotey",
    "created_on": "2020-06-29 02:32:11",
    "hub_plan": "hub",
    "members_count": 7,
    "status": "approved",
    "isActive": true,
  }, {
    "id": "da135753-1edd-4e20-b8e7-e1b79750e205",
    "S_No":2,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Graham-Towne",
    "email":"Graham@gmail.com",
    "hub_logo": "http://dummyimage.com/117x100.png/dddddd/000000",
    "created_by": "Heinrick Willcocks",
    "created_on": "2021-01-31 22:07:24",
    "hub_plan": "free",
    "members_count": 16,
    "status": "rejected",
    "isActive": false,
  }, {
    "id": "11aac723-1145-48d6-bb5c-4aac18c6eae1",
    "S_No":3,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Nader Group",
    "email":"Nader_Group@gmail.com",
    "hub_logo": "http://dummyimage.com/172x100.png/cc0000/ffffff",
    "created_by": "Florette Pack",
    "created_on": "2021-04-25 19:33:25",
    "hub_plan": "free",
    "members_count": 4,
    "status": "pending",
    "isActive": false,
  }, {
    "id": "b544ce02-b60b-4c4d-85f7-c3d975355d7a",
    "S_No":4,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Ledner-Brown",
    "email":"Ledner_Brown@gmail.com",
    "hub_logo": "http://dummyimage.com/173x100.png/5fa2dd/ffffff",
    "created_by": "Nobe Grishunin",
    "created_on": "2021-02-20 18:10:48",
    "hub_plan": "free",
    "members_count": 14,
    "status": "rejected",
    "isActive": true,
  }, {
    "id": "a800485e-d590-4c10-ad38-8aba46f3fd10",
    "S_No":5,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Bechtelar, Rice and Ruecker",
    "email":"Bechtelar@gmail.com",
    "hub_logo": "http://dummyimage.com/232x100.png/5fa2dd/ffffff",
    "created_by": "Calla Batchelor",
    "created_on": "2021-05-27 21:52:14",
    "hub_plan": "free",
    "members_count": 12,
    "status": "pending",
    "isActive": true,
  }, {
    "id": "dd0ce8ea-93db-4609-818d-539fc7c4f120",
    "S_No":6,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Heller, Kreiger and O'Reilly",
    "email":"Kreiger@gmail.com",
    "hub_logo": "http://dummyimage.com/118x100.png/dddddd/000000",
    "created_by": "Krishnah Dietz",
    "created_on": "2020-10-01 12:08:11",
    "hub_plan": "free",
    "members_count": 11,
    "status": "rejected",
    "isActive": false,
  }, {
    "id": "96d98056-b685-4f30-bae4-b21c8b579abf",
    "S_No":7,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Stracke-Kuvalis",
    "email":"Stracke@gmail.com",
    "hub_logo": "http://dummyimage.com/155x100.png/5fa2dd/ffffff",
    "created_by": "Brucie Soden",
    "created_on": "2020-10-06 12:50:14",
    "hub_plan": "business",
    "members_count": 9,
    "status": "pending",
    "isActive": true,
  }, {
    "id": "0e508696-b550-4c4f-a388-32669d4dbfab",
    "S_No":8,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Stiedemann, Kshlerin and Wunsch",
    "email":"Stiedemann@gmail.com",
    "hub_logo": "http://dummyimage.com/163x100.png/dddddd/000000",
    "created_by": "Tabbie Kiloh",
    "created_on": "2020-08-18 05:58:15",
    "hub_plan": "free",
    "members_count": 20,
    "status": "pending",
    "isActive": false,
  }, {
    "id": "16bb0b11-5ca4-4014-a9d2-d86c869e1042",
    "S_No":9,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Sauer LLC",
    "email":"Sauer@gmail.com",
    "hub_logo": "http://dummyimage.com/178x100.png/ff4444/ffffff",
    "created_by": "Dick Ferriere",
    "created_on": "2020-11-19 00:21:34",
    "hub_plan": "free",
    "members_count": 10,
    "status": "pending",
    "isActive": false,
  }, {
    "id": "c27ab592-17d9-4a5e-b5c6-d19cd2a85689",
    "S_No":10,
    "number":8654657456,
    "userPost":"Inappropriate content",
    "name": "Metz-Schaden",
    "email":"Metz@gmail.com",
    "hub_logo": "http://dummyimage.com/167x100.png/ff4444/ffffff",
    "created_by": "Ami Volage",
    "created_on": "2020-08-08 23:36:39",
    "hub_plan": "business",
    "members_count": 14,
    "status": "approved",
    "isActive": true,
  }]

  export const category =[
    {"id":1,
     "categoryName":"Weather",
     "imageUrl":"Weather",
     "created_on": "2020-06-29 02:32:11",
     "isActive": false,
    },
    {"id":2,
    "categoryName":"traffic",
    "created_on": "2020-11-19 00:21:34",
    "isActive": true,
   },
  ]
  
  export const banner=[
    {"id":1,
    "bannerImage":"https://res.cloudinary.com/mobiloitte-testing/image/upload/v1633521356/dmkqwk4ikxcdzb49mwbd.gif",
    "bannerContent":null,
    "created_on": "2020-06-29 02:32:11",
    "isActive": false,
   },
   {"id":2,
   "bannerImage":"https://res.cloudinary.com/mobiloitte-testing/image/upload/v1633521356/dmkqwk4ikxcdzb49mwbd.gif",
   "bannerContent":null,
   "created_on": "2020-11-19 00:21:34",
   "isActive": true,
  },
  ]