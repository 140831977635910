import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-user-posts-list-view',
  templateUrl: './user-posts-list-view.component.html',
  styleUrls: ['./user-posts-list-view.component.css']
})
export class UserPostsListViewComponent implements OnInit {
  id: any;
  about: any;
  // dataa: any=[];
  userData: any;
  date=new Date()
  postId:any;
  particularimg:any
  selectimage:any
  viewParticularPost:any;
  constructor(public service:CommonService, public route: ActivatedRoute, public router:Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(x=>{this.postId= x['id']; })
    this.viewPost()
  }

  select(img){
    // this.sidebarActive
    this.particularimg=img
  }
// view perticular category 
  viewPost(){
    this.service.showSpinner()
    let url =  `admin/viewPost?_id=${this.postId}`
    this.service.getApi(url,1).subscribe((res:any)=>{
     if(res.responseCode==200){

       this.viewParticularPost=res.result
       this.particularimg=this.viewParticularPost.imageLinks[0]
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
       this.service.hideSpinner()
       this.service.errorToast("data not found")
     }
    })
    }
    listpost() {
      this.router.navigate(["/user-post-list"],);
    }

    blockUnblockUser() {
      let apiReqUrl = `admin/blockUnblockPost?postId=${this.postId}`;
      this.service.showSpinner();
      this.service.putApi(apiReqUrl,{}, 1).subscribe((res:any) => {
          if (res.responseCode == 200) {
            this.service.hideSpinner();
            this.service.successToast(res.responseMessage);
            this.viewPost();
          } else {
            this.service.hideSpinner();
            this.service.errorToast(res.responseMessage);
          }
        },
      );
    }

}
