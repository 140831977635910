import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-static-contact-list',
  templateUrl: './static-contact-list.component.html',
  styleUrls: ['./static-contact-list.component.css']
})
export class StaticContactListComponent implements OnInit {
  staticContentList: any = [];
  userId: any;
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  constructor(
    private router: Router,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {

    this.getContactList();
  }


  staticView(id){
    this.router.navigate(["/contact-view"], { queryParams: {id: id} });
  }

  // get all static content list
  getContactList() {
    let url = `static/listContactUs`;
    console.log(url);
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {
          this.staticContentList = res.result.docs;
          this.totalItems=res.result.total
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
    );
  }
  pagination(event) {
    this.currentPage = event;
    this.getContactList();
  }

}
