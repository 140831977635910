import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $:any
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
  showSidebar: boolean
  currentUrl: any;
  sidebarActive:any='/dashboard';
  profileImage: any;
  side='sidebarColar'
  profileData: any=[];
  showLogo: boolean = false;
  showModal : boolean = false
  role: any;
  viewprofileData: any=[];
  sidemenu:any=[
    {"id":1,"routerLink":"/dashboard","icon":"fa fa-dashboard","Name":"DASHBOARD"},
    {"id":2,"routerLink":"/user-list","icon":"fa fa-user","Name":"USER MANAGEMENT"},
    {"id":3,"routerLink":"/user-post-list","icon":"fa fa-user","Name":"USER POST MANAGEMENT"},
    {"id":4,"routerLink":"/category-management","icon":"fa fa-list-alt","Name":"CATEGORY MANAGEMENT"},
    {"id":5,"routerLink":"/report-management","icon":"fa fa-file-alt","Name":"REPORT POST MANAGEMENT"},
    // {"id":6,"routerLink":"/banner-management","icon":"fa fa-picture-o","Name":"BANNER MANAGEMENT"},
    {"id":6,"routerLink":"/support","icon":"fa fa-support","Name":"SUPPORT"},
    {"id":7,"routerLink":"/static","icon":"fa fa-bullhorn","Name":"STATIC MANAGEMENT"},
    {"id":8,"routerLink":"/faq","icon":"fa fa-question-circle","Name":"FAQ'S MANAGEMENT"},
  ]
  constructor(public router: Router,public service:CommonService) { 
    // this.sidemenu
    this.service.loginSub.subscribe((res:any)=>{
      this.getProfile()
    })
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = '/'+ e.url.split('/')[1];
        this.sidemenu.filter(res=>{
          if(res.routerLink==this.currentUrl){  
            this.sidebarActive=res.routerLink
          }   
        })
        if (this.currentUrl === '/' || this.currentUrl === '/login' || this.currentUrl == '/forgot-password') {
          if (localStorage.getItem('token')) { this.router.navigate(['dashboard']) }
        }
      }
    });
    
  }


  showadmin(event) {
    console.log('event', event);
    if (this.showLogo == false) {
      if ((document.getElementById("admin").style.width = "45px")) {
        document.getElementById("admin").style.display = "none";
        this.showLogo = true;
      }
    }
  }


  ngOnInit(): void {
    // this.sidebarActive=localStorage.getItem("sidebar")
    $('.btn-toggle,.close_panel').click(() => {
      $('body').toggleClass('toggle-wrapper');
    });
    // this.getProfile()
  }


  onSelect(sidebar){
    this.sidebarActive=sidebar
  }

  getProfile() {
    let url = "admin/viewProfile"
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res.responseCode == 200) {
        this.profileData = res.result
      }
    }) 
  } 

  adminProfile() {
    this.router.navigate(['/profile'])
  }

  // logout
  logoutModalOpen() {
    $('#logoutModal').modal('show')
  }


}
