import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
declare var $:any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  showLoginHeader: boolean;
  showLogo: boolean = false;
  selected: any;
  showModal : boolean = false

  constructor(public service: CommonService) { }

  ngOnInit(): void {
  }
  
  head() {
    if (this.showLogo == false) {
      if ((document.getElementById("logo").style.width = "45px")) {
        document.getElementById("logo").style.display = "none";
        this.showLogo = true;
      }
    }
    else {
      document.getElementById("logo1").style.display = "none";
      this.showLogo = false;
    }
  }
  onLogout() {
    // $('#logoutModal').modal('hide');
    this.showModal = true
    this.service.logout();
    // localStorage.setItem('sidebar','/dashboard')
  }
  
}
