import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgOtpInputModule } from "ng-otp-input";
import { CKEditorModule } from "ngx-ckeditor";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { ErrorInterceptor } from "../interceptor/error.interceptor";
import { AddCategoryComponent } from "./add-category/add-category.component";
import { CategoryManagementComponent } from "./category-management.component";
import { CategoryRoutingModule } from "./category.routing.module";
import { EditCategoryComponent } from "./edit-category/edit-category.component";
import { ViewCategoryComponent } from "./view-category/view-category.component";

@NgModule({
    declarations:[
        CategoryManagementComponent,
        AddCategoryComponent,
        EditCategoryComponent,
        ViewCategoryComponent,
    ],
    imports:[
        CommonModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        // BrowserAnimationsModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        HttpClientModule,
        NgOtpInputModule,
        ToastrModule.forRoot({
        positionClass: "toast-top-right",
        maxOpened: 1,
        preventDuplicates: true,
        }),
        CategoryRoutingModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe],
})
export class CategoryModule{}