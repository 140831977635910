import { DatePipe } from '@angular/common';
import { Component,OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  // @Input() item = ''; 
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  itemsPerPageExcel:any
  totalItems: any;
  customerData: any = [];
  customerDataExcel:any=[];
  statusString: any;
  userId: any;
  status: any;
  today=new Date().toISOString().split('T')[0]

  constructor(
    private router: Router,
    public commonService: CommonService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    // this.customerData=[...dummy]
    this.searchFormValidation();
    this.getAllUserList();
    
  }

  // search form validation
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }

  search() {
    if(this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getAllUserList();
    }
  }

  reset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset();
      this.getAllUserList();
    }
  }
  getAllUserList() {
    let apiReqBody = {
      search: this.searchForm.value.search,
      fromDate: this.searchForm.value.fromDate,
      toDate:this.searchForm.value.toDate,
      page:this.currentPage,
      limit:this.itemsPerPage
    };
    let apiReqUrl=`admin/listUser`
    
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqBody, 1).subscribe((res:any) => {
      // console.log(res)
        if (res.responseCode == 200) {

          this.customerData = res.result.docs;
          this.totalItems = res.result.total;

          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },(err:any)=>{
        this.totalItems=0
        this.customerData=[]
       }
    );
  }



  pagination(event) {
    console.log(event);
    
    this.currentPage = event;
    this.getAllUserList();
  }

  viewUser(id,tab) {
    this.router.navigate(["/user-management"], { queryParams: {Id: id,tab:tab} });
  }

  getUserId(id,status) {
    this.userId = id;
    this.status=status
    if (this.status == "ACTIVE") {
      this.statusString = "BLOCK";
    } 
    else {
      this.statusString = "UNBLOCK";
    }
  }

  blockUnblockUser() {
    let apiReqUrl = `admin/userBlockUnblock?_id=${this.userId}`;
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl,{}, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getAllUserList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
    );
  }

  deleteUser() {
    let apiReqUrl = "admin/deleteUser?_id=" + this.userId;
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl,{}, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {
          
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getAllUserList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }
  exportAsXLSX() {
    let apiReqBody = {
      search: this.searchForm.value.search,
      fromDate: this.searchForm.value.fromDate,
      toDate:this.searchForm.value.toDate,
      limit:this.totalItems
    };
    let apiReqUrl=`admin/listUser`
    this.commonService.postApi(apiReqUrl, apiReqBody, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {
          this.customerDataExcel = res.result.docs;
          let dataArr = []; 
          this.customerDataExcel.forEach((element, ind) => {
            let obj = {};
            obj = {
              "S.No": ind + 1,
              "Name": element.fullName? element.fullName : "--",
              "Email": element.email ? element.email : "--",
              "Phone Number": element.phoneNumber ? element.phoneNumber : "--",
              "Created At": element.createdAt? this.datepipe.transform(element.createdAt, "dd/MM/yyyy, hh:mm a"): "--",
              "Status": element.status ? element.status : "--",
            };
          let array=  dataArr.push(obj);   
          }); 
          this.commonService.exportAsExcelFile(dataArr, "User Management");         
        }
      })
  }
}
