import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-faq',
  templateUrl: './view-faq.component.html',
  styleUrls: ['./view-faq.component.css']
})
export class ViewFaqComponent implements OnInit {
  ques : any;
  ans : any;
  date : any;
  userId: any;


  constructor(public service: CommonService, private activatedroute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.userId = res.id;
    })
    this.viewFaq();
  }

 viewFaq(){
   let url = 'static/viewFaq?_id='+this.userId
   this.service.showSpinner()
   this.service.getApi(url,1).subscribe((res:any) =>{
     if(res.responseCode == 200){
       this.ques = res.result.question
       this.ans = res.result.answer
       this.date = res.result.updatedAt
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
      this.service.successToast(res.responseMessage)
      this.service.hideSpinner()
     }
   })
 }
  
}
