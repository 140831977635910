
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">View post</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style" >
                        <div class="form-group row ">
                            <label class="col-md-4" >Name</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.userId?.fullName || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Username</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.userId?.userName || '--'}} </label>
                        </div>
                   
                        <div *ngIf="viewParticularPost?.mediaType=='IMAGE'" class="form-group row ">
                            <label class="col-md-4">Posted Image</label>
                            <label class="col-md-1"> : </label>
                            <label  class="col-md-6" >
                                <div class="row">
                                    <img *ngIf="viewParticularPost.imageLinks[0]"  [src]="particularimg" class="particularImage" alt="img" >
                                </div>
                              
                                <div class="row">
                                    <img *ngFor="let image of viewParticularPost?.imageLinks" class="col-md-4 postedImage"  [ngClass]="{'active':image == particularimg}" (click)="select(image)" [src]="image" alt="img" >
                                </div>
                                 <img *ngIf="!viewParticularPost?.imageLinks.length" class="postedImage" src="assets/img/no_img.png" alt="img" >
                            </label>
                        </div>
                        <div *ngIf="viewParticularPost?.mediaType=='VIDEO'" class="form-group row " >
                            <label class="col-md-4">Posted Video</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-6" >
                                <video width="320" height="240" controls>
                                    <source [src]="viewParticularPost?.videoLink" type="video/mp4">
                                  </video>
                           </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Description</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.description || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Posted Address</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.address || '--'}} </label>
                        </div>
          
                        <div class="form-group row ">
                            <label class="col-md-4">Media Type</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.mediaType || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4">Status</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{(viewParticularPost?.status | titlecase) || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-4" >Created Date & Time</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-7"> {{viewParticularPost?.createdAt | date:'medium'}}</label>
                        </div>
               
                        <div class="text-center mt40">
                            <!-- <a href="javascript:void(0)" class="btn btn-theme  max-WT-200 font-100 btn-green mr-2" (click)="saveAboutUS()">Update</a> -->
                            <button class="btn btn-theme  max-WT-200 font-100 btn-grey ml5" (click)="listpost(viewParticularPost?.userId?._id,'user-list')">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
