import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  view="hide"
  password="password"
  newPassword:boolean=true

  constructor(private router: Router, public commonService: CommonService) {}

  ngOnInit(): void {
    this.loginFormValidation();
  }

  //-------  login form validation -------- //
  loginFormValidation() {
    var data;
    if (localStorage.getItem("loginData")) {
      data = JSON.parse(localStorage.getItem("loginData"));
    }
    this.loginForm = new FormGroup({
      email: new FormControl(data?.email || "", [Validators.required, Validators.pattern( /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,7}|[0-9]{1,3})(\]?)$/i),]),
      password: new FormControl(atob(data?.password || ""), Validators.required),
      rememberMe: new FormControl(data?.rememberMe || ""),
    });
  }

  // ---------  login form submit ------------- //
  onLogin() {
    
    var loginData = {
      emailUserName: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    let apiReqUrl ="admin/login"
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl,loginData,0).subscribe((res:any)=>{
      if (res.responseCode == 200) {
        this.commonService.hideSpinner();
        localStorage.setItem("token", res.result.token);
        this.commonService.successToast(res.responseMessage);
        this.router.navigate(["dashboard"]);
        // remenber me
        if (this.loginForm.value.rememberMe) {
          var loginData = {
            email: this.loginForm.value.email,
            password: btoa(this.loginForm.value.password),
            rememberMe: this.loginForm.value.rememberMe,
          };
          localStorage.setItem("loginData", JSON.stringify(loginData));
        } else {  
          localStorage.removeItem("loginData");
        }
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    }) 
  }

}
