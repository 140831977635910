import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgOtpInputModule } from "ng-otp-input";
import { CKEditorModule } from "ngx-ckeditor";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "src/app/app.component";
import { CustomPipe } from "src/app/custom.pipe";
import { ErrorInterceptor } from "../interceptor/error.interceptor";
import { StaticContactListComponent } from "./static-contact-list/static-contact-list.component";
import { StaticContactViewComponent } from "./static-contact-view/static-contact-view.component";
import { StaticRoutingModule } from "./static-content-routing.module";
import { StaticContentComponent } from "./static-content.component";
import { StaticViewComponent } from "./static-view/static-view.component";



@NgModule({
    declarations:[
        StaticViewComponent,
        StaticContentComponent,
        StaticContactListComponent,
        StaticContactViewComponent,
        CustomPipe
    ],
    imports:[
        CommonModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        // BrowserAnimationsModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        HttpClientModule,
        NgOtpInputModule,
        ToastrModule.forRoot({
        positionClass: "toast-top-right",
        maxOpened: 1,
        preventDuplicates: true,
        }),
        StaticRoutingModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe,CustomPipe],
    bootstrap: [AppComponent]
})
export class StaticModule { }