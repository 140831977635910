<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">Edit Category</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">
                        <div class="form-group row ">
                            <label class="col-md-3" >Category Id</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-8"> {{this.pagekey}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-3">Category Name</label>
                            <label class="col-md-1"> : </label>
                            <div class="col-md-4">
                                <form action="" [formGroup]="editCategoryForm">
                                    <input type="text" class="form-control" placeholder="Category Name" (keypress)="service.preventSpace($event)" formControlName="categoryName" [(ngModel)]="categoryName" maxlength="60">
                                <div class="validations">
                                    <span *ngIf="(editCategoryForm.controls['categoryName'].invalid && (editCategoryForm.controls['categoryName'].dirty || editCategoryForm.controls['categoryName'].touched))">
                                        *Please enter category name.
                                    </span>
                                </div>
                                </form>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-3" >Upload Image</label>
                            <label class="col-md-1"> : </label>
                            <input type="file" class="col-md-6" (change)="uploadFile($event)">
                        </div>
                        <div class="row">
                        <label class="col-md-3" ></label>
                        <label class="col-md-1"> </label>
                        <div class="col-md-6">
                            <img [src]="image" alt="" >
                        </div>
                       </div>
                        <div class="text-center mt40">
                            <button class="btn btn-theme  max-WT-200 font-100 btn-green mr-2" [disabled]="!editCategoryForm.value.categoryName || !image" (click)="getEditCategory()">Update</button>
                            <button class="btn btn-theme  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/category-management']">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
