<section class="vh-100" style="background-color: aliceblue;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col col-xl-10">
          <div class="card" style="border-radius: 1rem;">
            <div class="row g-0">
              <div class="col-md-6 col-lg-5 d-none d-md-block">
                <img
                  src="/assets/img/strom_street.jpg"
                  alt="login form"
                  class="img-fluid" style="border-radius: 1rem 0 0 1rem;"
                />
              </div>
              <div class="col-md-6 col-lg-7 d-flex align-items-center">
                <div class="card-body p-4 p-lg-5 text-black">
  
                  <form [formGroup]="resetPassword">
  
                    <!-- <div class="d-flex align-items-center mb-3 pb-1">
                      <i class="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i>
                      <span class="h1 fw-bold mb-0">Logo</span>
                    </div> -->
  
                    <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">RESET PASSWORD</h5>
  
                    <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example17" >New Password</label>
                      <input type="password" id="form2Example17" formControlName="password" class="form-control" maxlength="50" placeholder="Enter new password" class="form-control form-control-lg" />
                      <div class="text-danger" *ngIf="resetPassword.get('password').hasError('required') && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                        *Please enter new password.
                      </div>
                      <div class="text-danger" *ngIf="resetPassword.get('password').hasError('pattern') && !resetPassword.get('password').hasError('required') && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                        *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.(example- Mobiloitte@1)
                      </div>
                     
                    </div>
  
                    <div class="form-outline mb-4">
                      <label class="form-label" for="form2Example27">Confirm New Password</label>
                      <input type="password" id="form2Example27" maxlength="16" formControlName="confirmPassword" class="form-control" placeholder="Enter confirm new password" class="form-control form-control-lg" />
                      <div class="text-danger" *ngIf="(resetPassword.value.password != resetPassword.value.confirmPassword) && resetPassword.get('confirmPassword').dirty ">
                        *New password and confirm new password must be matched.
                       </div>
                    </div>
  
                    <div class="pt-1 mb-4">
                      <button class="btn btn-dark btn-lg btn-block" type="button" [disabled]="!resetPassword.valid" (click)="resetPasswordApi()">Submit</button>
                    </div>
       
                  </form>
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  

  
  
  