import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { dummy } from '../../user-management/dummy';

declare var $;
@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent implements OnInit {
  userId: any;
  userData: any;
  status:any;
  particularimg:any
  searchForm : FormGroup

  constructor(public service : CommonService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.userId = res.Id
    })
  }

  ngOnInit(): void {
    this.getReport()
    this.searchForm = new FormGroup({
      report : new FormControl("",Validators.required)

    })
  }

  getReport(){
    let url =`admin/viewReport?_id=${this.userId}`
    // let data={_id:this.userId}
    // this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res:any)=>{
      if (res.responseCode == 200) {
        this.userData = res.result
        this.particularimg=this.userData.postId.imageLinks[0]
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      } else {
        this.service.hideSpinner()
        this.service.errorToast("Data not found.")
      }
    })
  }
  select(img){
    // this.sidebarActive
    this.particularimg=img
  }
  viewUserData(){
  this.userData= this.userData.find(h=>h.id==this.userId)
  }
  getUserId(id, status) {
    this.userId = id;
    this.status=status
    if (this.status == "AWAIT" || "RESPONDED") {
      this.ReportModalOpen()
    } 

  }
// block-unblock user post 
blockUnblockPost() {
  let url = `admin/blockPostAndComment?reportId=${this.userId}`;
  // this.commonService.showSpinner();
  // console.log(this.searchForm.value.report);
  let apiReqData={
     "comment":this.searchForm.value.report
  }
  this.service.showSpinner();
  this.service.putApi(url,apiReqData, 1).subscribe(
    (res:any) => {
      if (res.responseCode == 200) {
        this.ReportModalClose()
        this.searchForm.reset()
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage);
        this.getReport();
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    });
}
ReportModalClose() {
  $('#ReportModal').modal('hide')
}
ReportModalOpen() {
  $('#ReportModal').modal('show')
}
}
