import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { AddFaqComponent } from './add-faq/add-faq.component';
import { EditFaqComponent } from './edit-faq/edit-faq.component';
import { FAQComponent } from './faq.component';
import { ViewFaqComponent } from './view-faq/view-faq.component';


const routes: Routes = [
    { path: "faq",component:FAQComponent,canActivate:[AuthGuard]},
    { path: "add-faq",component:AddFaqComponent,canActivate:[AuthGuard]},
    { path: "view-faq",component:ViewFaqComponent,canActivate:[AuthGuard]},
    { path: "edit-faq",component:EditFaqComponent,canActivate:[AuthGuard]},
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FaqRoutingModule { }