import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $:any
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  isCheckedAll: any = false;
  currTab:any;
  userId: any;
  constructor(private router: Router,public activateRoute:ActivatedRoute, public service:CommonService) { 
    this.activateRoute.queryParams.subscribe(res=>{
      this.userId=res.Id
      this.currTab = res.tab
    })   
  }

  ngOnInit() {

  }


selectTab(tab) {
  this.currTab=tab
}

  

}