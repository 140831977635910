import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-support',
  templateUrl: './view-support.component.html',
  styleUrls: ['./view-support.component.css']
})
export class ViewSupportComponent implements OnInit {
  userId: any;
  userData: any;
  replyForm:FormGroup
  email:any

  constructor(private service : CommonService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.userId = res.id
    })
  }
  ngOnInit(): void {
    this.replyForm = new FormGroup({
      'subject':new FormControl("",Validators.required),
      'message':new FormControl("",Validators.required)
    })
        this.getContactView()
  }
  getContactView(){
    let url =`static/viewContactUs?_id=${this.userId}`
    // let data={_id:this.userId}
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res:any)=>{
      if (res.responseCode == 200) {
        this.userData = res.result
        this.email = res.result.userEmail
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      } else {
        this.service.hideSpinner()
        this.service.errorToast("Data not found.")
      }
    })
  }

  replyUser(subject,body){
    let email = this.email
    var mail = document.createElement("a");
    mail.href = `mailto:${email}?Subject=${subject}&body=${body}`
    mail.click()
    this.replyForm.reset()
  }
}
