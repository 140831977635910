import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { category } from '../../user-management/dummy';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.css']
})
export class ViewCategoryComponent implements OnInit {
  id: any;
  about: any;
  // dataa: any=[];
  userData: any;
  date=new Date()
  pagekey:any
  viewPerticularCategory:any=[];
  constructor(public service:CommonService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.userData=[...category]
    this.route.queryParams.subscribe(x=>{this.pagekey= x['Id']; })
    this.viewCategory()
  }
// view perticular category 
  viewCategory(){
    this.service.showSpinner()
    let url =  `admin/viewCategory?_id=${this.pagekey}`
    // this.userData=this.userData.find(h=>h.id==this.pagekey)
    this.service.getApi(url,1).subscribe((res:any)=>{
     if(res.responseCode==200){
       this.viewPerticularCategory=res.result
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
       this.service.hideSpinner()
       this.service.errorToast("data not found")
     }
    })
    }

}
