import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { RouterModule } from "@angular/router";
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgOtpInputModule } from "ng-otp-input";
import { CKEditorModule } from "ngx-ckeditor";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "src/app/app.component";
import { ErrorInterceptor } from "../interceptor/error.interceptor";
import { UserFollowerViewComponent } from "./user-follower-view/user-follower-view.component";
import { UserFollowersComponent } from "./user-followers/user-followers.component";
import { UserFollowingViewComponent } from "./user-following-view/user-following-view.component";
import { UserFollowingsComponent } from "./user-followings/user-followings.component";
import { UserGeneralInformationComponent } from "./user-general-information/user-general-information.component";
import { UserListComponent } from "./user-list/user-list.component";
import { UserManagementRoutingModule } from "./user-management-routing.module";
import { UserManagementComponent } from "./user-management.component";
import { UserPostListComponent } from "./user-post-list/user-post-list.component";
import { UserPostViewComponent } from "./user-post-view/user-post-view.component";


@NgModule({
    declarations:[
        UserManagementComponent,
        UserListComponent,
        UserGeneralInformationComponent,
        UserPostListComponent,
        UserPostViewComponent,
        UserFollowersComponent,
        UserFollowingsComponent,
        UserFollowerViewComponent,
        UserFollowingViewComponent,
    ],
    imports:[
        CommonModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        HttpClientModule,
        NgOtpInputModule,
        ToastrModule.forRoot({
        positionClass: "toast-top-right",
        maxOpened: 1,
        preventDuplicates: true,
        }),
        UserManagementRoutingModule
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe],
    bootstrap: [AppComponent]
})
export class UserManagementModule { }