<aside class="sidebar" style="z-index: 7">
    <div class="adminprofile">
        <a class="navbar-brand logo" style="width: 100%; display: inline-flex; justify-content: center;">
            <img class="lg-logo side-img" [src]="profileData?.profilePic|| 'assets/Product image/user.png'" alt="image" (click)="adminProfile()">
        </a>
        <div id="admin" class="profile-data-section">
            <label style="word-break: break-word;">{{profileData?.fullName  || 'Admin' }} <br *ngIf="[profileData?.firstName].length > 10"> {{profileData?.lastName}}</label>
            <p>{{profileData?.email || 'admin@gmail.com' }}</p>
        </div>
    </div>
    <div class="sidebar-scroller">
        <div id="accordion" class="sidebar-menu">
            <div class="menu-box toggle-menu" (click)="onSelect(item.routerLink)" [ngClass]="{'active':item.routerLink == sidebarActive}"   *ngFor="let item of sidemenu" >
                <div class="card-header clearfix" [routerLink]="item.routerLink"  id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i [class]="item.icon" aria-hidden="true"></i></span>
                        <span class="side_page_name head_color">{{item.Name}}</span>
                    </a>
                </div>
             </div>
            <!-- <div class="menu-box toggle-menu" [ngClass]="{'active':currentUrl == '/list-user' || currentUrl?.includes ('/view-user')}" >
                <div class="card-header clearfix" [routerLink]="['/list-user']" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span>
                        <span class="side_page_name head_color">USER MANAGEMENT</span>
                    </a>
                </div>
            </div> -->
            <div class="menu-box">
                <div class="card-header clearfix" id="headingOne" data-toggle="modal" data-target="#logoutModal1">
                    <a class="side_menu" >
                        <span class="side_menu_icon">
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </span>
                        <span class="side_page_name head_color">LOG OUT</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</aside>

