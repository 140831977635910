import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { CountryJsonService } from 'src/app/provider/country-json.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  editProfileForm: FormGroup;
  // stateArr : any = [];
  countryList: any = [];
  stateList: any = [];
  id : any;
  userDetail: any;
  editImage: any;
  imgSrc: string;
  username:any

  constructor(public commonService:CommonService,public countryService:CountryJsonService, private router:Router) {
    
   }

  ngOnInit(): void {
    this.editProfileFormValidation();
    this.getProfile()
    // this.countryList = this.countryService.countryListJson;
  }

  editProfileFormValidation() {
    this.editProfileForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'phoneNo': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/),  Validators.maxLength(15)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'image': new FormControl(''),
      'usertype':new FormControl(''),
      'country' : new FormControl('',Validators.required),
      'state' : new FormControl('',Validators.required),
      'username':new FormControl('',Validators.required),
      'city' :  new FormControl('',Validators.required),
      'address': new FormControl('',[Validators.required])
    })
  }
  // ------------ Api of admin profile -------------//
  getProfile() {
    var apiReqUrl = 'admin/viewProfile';
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl,1).subscribe((res:any) => {
      // this.commonService.adminProfile.subscribe((res:any)=>{
      if (res.responseCode == 200) {
        this.userDetail = res.result
        this.editProfileForm .patchValue({
          'firstName': this.userDetail.fullName,
          'email': this.userDetail.email,
          'username':this.userDetail.userName,
          'usertype':this.userDetail.userType,
          'phoneNo': this.userDetail.mobileNumber,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country,
          'state':this.userDetail.State ,
        })
        this.username=res.result.userName
        this.commonService.hideSpinner();
        this.imgSrc = this.userDetail.profilePic
        // this.getState()
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res['message']);
      }
    })
  } 


  getStateList(event) {
    var stateList = this.countryList.filter(x => (x.country == event.target.value));
    this.stateList = stateList[0].states;
    // this.countryCode = stateList[0].code;
  }
  // ------------ Api of edit profile -------------//
  editProfile() {
    let apiReqUrl="admin/editProfile"
    var apiReqData = {
      "fullName": this.editProfileForm.value.firstName,
      // "address": this.editProfileForm.value.address,
      // "countryCode":this.editProfileForm.value.phoneNo,
      // "state":this.editProfileForm.value.state,
      // "city": this.editProfileForm.value.city,
      // "userName":this.editProfileForm.value.username,
      // "country": this.editProfileForm.value.city,
      "mobileNumber": this.editProfileForm.value.phoneNo,
      "email": this.editProfileForm.value.email,
      "profilePic": this.imgSrc? this.imgSrc : this.userDetail.profilePic
    }
    if(this.username!=this.editProfileForm.value.username){
      apiReqData["userName"]=this.editProfileForm.value.username
    }
   
    // const x = apiReqData;
    // var txt=''
    // for(let x in apiReqData)
    // txt =txt+apiReqData[x]+" ----  "
    // console.log(txt);
      this.commonService.showSpinner();
      this.commonService.putApi(apiReqUrl, apiReqData,1).subscribe((res:any) => {
      if (res['responseCode'] == 200) {
        // localStorage.setItem("token", res.result.token);
        this.commonService.changeLoginSub(res.result)
        this.commonService.hideSpinner();
        this.commonService.successToast('Profile Updated Successfully');
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }
  
// Image Functionality Start Here

uploadImg($event): void {
  var img = $event.target.files[0];
  this.uploadImageFunc(img);
}
uploadImageFunc(img) {
  var image = new FormData();
  image.append('uploaded_file', img)
  var apiReqUrl="admin/uploadFile"
  this.commonService.showSpinner();
  this.commonService.postFormDataApi(apiReqUrl,image,1).subscribe((res:any) => {
    this.commonService.hideSpinner();
    if (res['responseCode'] == '200') {
      this.imgSrc = res['result'].mediaUrl;
      this.commonService.hideSpinner();
      this.commonService.successToast(res.responseMessage);
    }
    else {
      this.commonService.hideSpinner();
      this.commonService.errorToast(res.responseMessage);
    }
  })
}
  
  back() {
    this.router.navigate(['/change-password'])
  }
}
