import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $
@Component({
  selector: 'app-static-contact-view',
  templateUrl: './static-contact-view.component.html',
  styleUrls: ['./static-contact-view.component.css']
})
export class StaticContactViewComponent implements OnInit {
  addlink = false
  editlink:boolean;
  userId: any;
  userData: any = [];
  toggle:boolean=false
  idSocialLink:any
  editable:boolean=false
  socialId:any;
  socialEdit:any
  SocialName:any;
  socialLink:any = []
  isSocialLink : any = []

  contactForm:FormGroup
  constructor(private service : CommonService,private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.userId = res.id
    })
  }

  ngOnInit(): void {
    // this.userData=[...dummy] 
this.contactForm = new FormGroup({
  // SocialName:new FormControl("",Validators.required),
  // SocialLink:new FormControl("",Validators.required)
})
    this.getContactView()
  }

  getContactView(){
    let url =`admin/listSocialLink`
    // let data={_id:this.userId}
    // this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res:any)=>{
      if (res.responseCode == 200) {
        this.userData = res.result
        for(let i=0;i<this.userData.length;i++){
          this.isSocialLink[i]=false
         
          this.socialLink[i]=this.userData[i].socialLink
        }
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      } else {
        this.service.hideSpinner()
        this.service.errorToast("Data not found.")
      }
    })
  }


  updateSocialLink(link){
   if(!link){
     this.service.warningToast("Can't save empty link.")
     return
   }
    let url =`admin/editSocialLink?_id=${this.socialId}`
    let data = {
    socialName:this.socialName,
    socialLink:link,
    }
  console.log(url,data);
  
    // this.editable=true
    this.service.showSpinner()
    this.service.putApi(url,data,1).subscribe((res:any)=>{
      if (res.responseCode == 200) {
        // this.closeModel()
         this.getContactView()
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
       
      } else {
        this.service.hideSpinner()
        this.service.errorToast("Data not found.")
      }
    })
  }
  socialName : any
  socialLinks : any

  socialLinkEdit(i,socialLink,socialName,id){
    
    for(let i=0;i<this.userData.length;i++){
      this.isSocialLink[i]=false
     
    }
    this.isSocialLink[i] = true
    console.log(i,socialLink,socialName,id);
    this.socialName = socialName
    this.socialLinks = socialLink
    this.socialId = id
   
    // this.contactForm.reset()
  }
 


}
