<!-- <p>user-list works! {{item}}</p> -->
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Category Management</h1>
    </div>
    <div class="content-section">
    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">

                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">

                                <form [formGroup]="searchForm">
                                    <div class="row align-items-center">
                                        <div class="col-md-4 pr-0">
                                            <div class="filter_search mb20 width100">
                                                <label class="d-flex align-items-center">Search:</label>
                                                <div class="input-group filter_search_group">
                                                    <input type="text" class="form-control overflow-text" formControlName="search" placeholder="Search by category name" maxlength="60">
                                                    <div class="input-group-append">
                                                        <button class="btn btn_search_group"><img src="assets/img/icon-search.png" (click)="search()"  alt="Search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="text-center admin-btn mb2 mt10">
                                                <button type="button" class="btn btn-theme" (click)="search()">Search</button>
                                                <button type="button" class="btn btn-theme" (click)="reset()">Reset</button>
                                                <button type="button" class="btn btn-theme" style="margin-left: auto;" routerLink="/add-category" >Add new</button>
                                            </div>
                                               
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive" style="margin-bottom: 30px;">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                <thead class="thead">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img heading1">S.No</th>
                                        <th scope="col" class="Sorting-img">Category Name</th>
                                        <th scope="col" class="Sorting-img">Category Image</th>
                                        <th scope="col" class="Sorting-img">Created Date & Time</th>
                                        <th scope="col" class="Sorting-img">Status</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img heading2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let item of categoryList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index">
                                        <td>{{i+1+(currentPage-1)*itemsPerPage}}</td>
                                        <td class="content">{{(item.categoryName  | titlecase )||'--'}}</td>
                                        <td class="content">
                                            <img src="{{item.image}}" alt="" class="catImg" >
                                        </td>
                                        <td class="content">{{item.createdAt | date:'medium'}}</td>
                                        <td class="content">{{(item.status | titlecase) || '--'}}</td>
                                        <td class="justify-content-center d-flex">
                                            <button class="btn btn-info ml-2 bt-margin bth" title="View" routerLink="/view-category" (click)="categoryView(item._id)">
                                                <em class="fa fa-eye" aria-hidden="true"></em>
                                            </button>
                                            <button class="btn btn-gray ml-2 bth" title="edit" routerLink="/edit-category" (click)="categoryEdit(item._id,item.categoryName)">
                                                <em class="fa fa-edit" aria-hidden="true"></em>
                                            </button>
                                            <button class="btn btn-danger ml-2 bth" title="Delete" data-toggle="modal"  (click)="deletModal(item._id)"  >
                                                <em class="fa fa-trash" aria-hidden="true"></em>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="!categoryList.length">
                                        <td colspan="6">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt-2 text-align-end" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
            </div>
        </div>
    </div>
</div>
</main>
<!-- delete user-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
<div class="modal-dialog max-WT-500">
<form class="change_password">
    <div class="modal-content">
        <div class="modal-header d-block text-center modal-header-custm">
            <h5 class="modal-title d-inline-block">Delete</h5>
        </div>
        <div class="modal-inner-content">
            <div class="modal-body">
                <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                    <div class="w-100">
                        <p>Are you sure you want to delete this user?</p>
                        <div class="modal-btn-box">
                            <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="deleteUser()">Yes</button>
                            <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div>
</div>



