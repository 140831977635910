<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title">View Banner</h1>
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">
                        <div class="form-group row ">
                            <label class="col-md-3" >Banner Image</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-8"> <img [src]= "userData.bannerImage" alt="img"></label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-3">Banner Title</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-8 banner-title"> {{(userData.bannerTitle | titlecase ) || '--'}} </label>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-3" >Created Date & Time</label>
                            <label class="col-md-1"> : </label>
                            <label class="col-md-8"> {{userData.createdAt | date:'medium'}}</label>
                            <!-- <div class="col-md-8" style="margin-top: -15px;max-height: 30px;">
                                <textarea class="form-control1 common-textarea" readonly [(ngModel)]="dataa.pageKey" ></textarea>
                            </div> -->
                        </div>
               
                        <div class="text-center mt40">
                            <!-- <a href="javascript:void(0)" class="btn btn-theme  max-WT-200 font-100 btn-green mr-2" (click)="saveAboutUS()">Update</a> -->
                            <a href="javascript:void(0)" class="btn btn-theme  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/banner-management']">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
