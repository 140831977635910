import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
contentList:any=[];
searchForm:FormGroup;
itemsPerPage = 10;
currentPage = 1;
total: any;
value:any;
postId:any
  constructor(private service:CommonService, private router: Router) { }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      'search' : new FormControl("")
    })
    this.getContectList()
  }
  pagination(event) {
    this.currentPage = event;
    this.getContectList()
  }

  getContectList(){
    let data = {
      search : this.searchForm.value.search,
      page : this.currentPage,
      limit : this.itemsPerPage
    }
    this.service.showSpinner()
    let url = `static/listContactUs`
    this.service.postApi(url,data,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.contentList = res.result.docs
        this.total = res.result.total,
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    })
  }

  viewSupport(id){
    this.router.navigate(["/view-support"], { queryParams: {id: id} });
  }


  updateStatus(id,value){
    this.postId = id
    // console.log(value,id);
    let data = {
      responseStatus: value=="AWAIT"?"RESPONDED":"AWAIT"
     }
    //  console.log(data);
     
    // this.service.showSpinner()
  //  if(value == "RESPONDED"){
  //    var data = {
  //     responseStatus: "AWAIT"
  //    }
  //  }
  //  else if(value == "AWAIT"){
  //   var data = {
  //    responseStatus: "RESPONDED"
  //   }
  // }
    let url = `static/updateContactUs?_id=${this.postId}`
    this.service.putApi(url,data,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.service.successToast(res.responseMessage)
        this.service.hideSpinner()
        this.getContectList()
      }
      else{
        this.service.errorToast(res.responseMessage)
        this.service.hideSpinner()
      }
    })
  }

  search() {
    if(this.searchForm.value.search) {
      this.getContectList();
    }
  }

  reset() {
    if (this.searchForm.value.search ) {
      this.searchForm.reset();
      this.getContectList();
    }
  }


}