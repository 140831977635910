<main class="middle-content">
    <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
            <div class="  mb40">
                <div class="admin-profile">
                    <h4 class="mb20 text-center" style="color: #404393;">Profile</h4>
                </div>
                <div class="add-store-block input-style mb20 mt20 ">
                    <form [formGroup]="editProfileForm" >
                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="imgSrc ||'assets/img/profile-img.jpg'" id="sub-admin-image" alt="">
                                <label class="upload-label">
                                    <input type="file" formControlName="image" name="image" accept="image/jpg,image/jpeg,image/png" id="image" (change)="uploadImg($event)" maxlength="56">
                                    
                                    <em class="fa fa-pencil"></em>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright ">Name </label><span class="col-md-1">:</span>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Name"
                                        formControlName="firstName" (keypress)="commonService.preventSpace($event)"
                                        maxlength="60">
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('firstName').hasError('required') && (editProfileForm.get('firstName').dirty || editProfileForm.get('firstName').touched)">*Please enter first Name.
                                        </span>
                                        <span *ngIf="editProfileForm.get('firstName').hasError('pattern') && (editProfileForm.get('firstName').dirty )">*please enter valid first name.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright">User name</label><span class="col-md-1">:</span>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="tel" class="form-control" placeholder="username"
                                        formControlName="username" (keypress)="commonService.preventSpace($event)"
                                        maxlength="60">
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('username').hasError('required') && (editProfileForm.get('username').dirty || editProfileForm.get('username').touched)">
                                            *Please enter your username.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright">Email</label><span class="col-md-1">:</span>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="text" class="form-control" placeholder="Email Address" formControlName="email" (keypress)="commonService.preventSpace($event)" maxlength="50" disabled>
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('email').hasError('required') && (editProfileForm.get('email').dirty || editProfileForm.get('email').touched)">
                                            *Please enter you email.
                                        </span>
                                        <span *ngIf="editProfileForm.get('email').hasError('pattern')">
                                            *Please enter valid email.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-3 textalignright">User type</label><span class="col-md-1">:</span>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="text" class="form-control" placeholder="user type" formControlName="usertype" (keypress)="commonService.preventSpace($event)" maxlength="50" disabled>
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('usertype').hasError('required') && (editProfileForm.get('usertype').dirty || editProfileForm.get('usertype').touched)">
                                            *Please enter you usertype.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Mobile Number <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="tel" class="form-control" placeholder="Mobile Number" formControlName="phoneNo" (keypress)="commonService.preventSpace($event)" maxlength="15">
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('phoneNo').hasError('required') && (editProfileForm.get('phoneNo').dirty || editProfileForm.get('phoneNo').touched)">
                                            *Please enter you number.
                                        </span>
                                        <span *ngIf="editProfileForm.get('phoneNo').hasError('pattern')">
                                            *Please enter valid number.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Country<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                        <select class="form-control form-select" formControlName="country"  (change)="getStateList($event)" >
                                            <option [value]="" >Select Country</option>
                                            <option [value]=name.country *ngFor = "let name of countryList"> {{name.country}}</option>
                                        </select>
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('country').hasError('required') && (editProfileForm.get('country').dirty || editProfileForm.get('country').touched)">
                                            *Please enter you number.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">State<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                        <select class="form-control form-select" formControlName="state">
                                            <option value="">Select State</option>
                                            <option value={{state}} *ngFor = "let state of stateList">{{state}}</option>
                                        </select>
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('country').hasError('required') && (editProfileForm.get('country').dirty || editProfileForm.get('country').touched)">
                                            *Please enter you country.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">City<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="tel" class="form-control" placeholder="City"
                                        formControlName="city" (keypress)="commonService.preventSpace($event)"
                                        maxlength="15">
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('city').hasError('required') && (editProfileForm.get('city').dirty || editProfileForm.get('city').touched)">
                                            *Please enter you city.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-4 textalignright">Address<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <input type="tel" class="form-control" placeholder="Address"
                                        formControlName="address" (keypress)="commonService.preventSpace($event)"
                                        maxlength="60">
                                    <div class="text-danger">
                                        <span *ngIf="editProfileForm.get('address').hasError('required') && (editProfileForm.get('address').dirty || editProfileForm.get('address').touched)">
                                            *Please enter your address.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </form>
                </div>
                <div class="mt40 mb40 text-center" class="backupdatebutton">
                    <button class="btn btn-large  max-WT-150 btn-secondary" (click)="back()">Change Password</button>
                    <button class="btn btn-large  max-WT-150 btn-secondary ml-2" style="margin-left: 1%;" (click)="editProfile()">Update</button>
                </div>
            </div>
        </div>
    </div>
    </main>
    