import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SidemenuComponent } from './pages/sidemenu/sidemenu.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { HeaderComponent } from './pages/sharing/header/header.component';
import { FooterComponent } from './pages/sharing/footer/footer.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReportManagementComponent } from './pages/report-management/report-management.component';
import { ProfileComponent } from './pages/auth/profile/profile.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { ReportViewComponent } from './pages/report-management/report-view/report-view.component';
import { BannerManagementComponent } from './pages/banner-management/banner-management.component';
import { AddBannerComponent } from './pages/banner-management/add-banner/add-banner.component';
import { ViewBannerComponent } from './pages/banner-management/view-banner/view-banner.component';
import { ErrorInterceptor } from './pages/interceptor/error.interceptor';
import { NgOtpInputModule } from 'ng-otp-input';
import { DatePipe } from '@angular/common';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { FaqModule } from './pages/faq/faq.module';
import { StaticModule } from './pages/static-content/static-content.module';
import { CategoryModule } from './pages/category-management/category.module';
import { EditBannerComponent } from './pages/banner-management/edit-banner/edit-banner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UserPostsListComponent } from './pages/user-post-managment/user-posts-list/user-posts-list.component';
import { UserPostsListViewComponent } from './pages/user-post-managment/user-posts-list-view/user-posts-list-view.component';
import { UserManagementModule } from './pages/user-management/user-management.module';
import { CustomPipe } from './custom.pipe';
import { SupportComponent } from './pages/support/support.component';
import { ViewSupportComponent } from './pages/support/view-support/view-support.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    SidemenuComponent,
    HeaderComponent,
    FooterComponent,
    ReportManagementComponent,
    ProfileComponent,
    ReportViewComponent,
    BannerManagementComponent,
    AddBannerComponent,
    ViewBannerComponent,
    ChangePasswordComponent,
    EditBannerComponent,
    NotFoundComponent,
    UserPostsListComponent,
    UserPostsListViewComponent,
    SupportComponent,
    ViewSupportComponent,
  ],
  imports: [
    
    FormsModule,
    CKEditorModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    HttpClientModule,
    NgOtpInputModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      maxOpened: 1,
      preventDuplicates: true,
    }),
    UserManagementModule,
    AppRoutingModule,
    StaticModule,
    FaqModule,
    CategoryModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe,CustomPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
