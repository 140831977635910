import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { StaticContactListComponent } from './static-contact-list/static-contact-list.component';
import { StaticContactViewComponent } from './static-contact-view/static-contact-view.component';
import { StaticContentComponent } from './static-content.component';
import { StaticViewComponent } from './static-view/static-view.component';

const routes: Routes = [
    { path: "static",component:StaticContentComponent,canActivate:[AuthGuard]},
    { path: "static-view",component:StaticViewComponent,canActivate:[AuthGuard]},
    { path: "contact-view",component:StaticContactViewComponent,canActivate:[AuthGuard]},
    // { path: "contact-list",component:StaticContactListComponent,canActivate:[AuthGuard]}
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StaticRoutingModule { }