import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './pages/auth/forget-password/forget-password.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { ProfileComponent } from './pages/auth/profile/profile.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ReportManagementComponent } from './pages/report-management/report-management.component';
import { ReportViewComponent } from './pages/report-management/report-view/report-view.component';
import { BannerManagementComponent } from './pages/banner-management/banner-management.component';
import { AddBannerComponent } from './pages/banner-management/add-banner/add-banner.component';
import { ViewBannerComponent } from './pages/banner-management/view-banner/view-banner.component';
import { ChangePasswordComponent } from './pages/auth/change-password/change-password.component';
import { AuthGuard } from './auth.guard';
import { EditBannerComponent } from './pages/banner-management/edit-banner/edit-banner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { UserPostsListComponent } from './pages/user-post-managment/user-posts-list/user-posts-list.component';
import { UserPostsListViewComponent } from './pages/user-post-managment/user-posts-list-view/user-posts-list-view.component';
import { SupportComponent } from './pages/support/support.component';
import { ViewSupportComponent } from './pages/support/view-support/view-support.component';
const routes: Routes = [
  { path: "" ,redirectTo:"login",pathMatch:"full"},
  { path: "login", component: LoginComponent },
  { path: "forgetPassword", component: ForgetPasswordComponent },
  { path: "reset-password",component:ResetPasswordComponent},
  { path: "change-password",component:ChangePasswordComponent,canActivate:[AuthGuard]},
  { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard]},
  { path: "user-post-list",component:UserPostsListComponent,canActivate:[AuthGuard]},
  { path: "user-post-list-view",component:UserPostsListViewComponent,canActivate:[AuthGuard]},
  { path: "report-management",component:ReportManagementComponent,canActivate:[AuthGuard]},
  { path: "banner-management",component:BannerManagementComponent,canActivate:[AuthGuard]},
  { path: "add-banner" ,component:AddBannerComponent,canActivate:[AuthGuard]},
  { path: "edit-banner" ,component:EditBannerComponent,canActivate:[AuthGuard]},
  { path: "view-banner",component:ViewBannerComponent,canActivate:[AuthGuard]},
  { path: "report-view",component:ReportViewComponent,canActivate:[AuthGuard]},
  { path: "profile",component:ProfileComponent,canActivate:[AuthGuard]},
  { path: "",loadChildren:()=>import('./pages/user-management/user-management.module').then(m=>m.UserManagementModule)},
  { path: "", loadChildren:()=>import('./pages/static-content/static-content.module').then(m=>m.StaticModule)},
  { path: "",loadChildren:()=>import('./pages/category-management/category.module').then(m=>m.CategoryModule)},
  { path: "",loadChildren:()=>import('./pages/faq/faq.module').then(m=>m.FaqModule)},
  { path: "support", component:SupportComponent},
  { path: "view-support", component:ViewSupportComponent},
  { path: "not-found", component: NotFoundComponent },
  { path: '**', redirectTo:'not-found'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
