import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
// import { $ } from 'protractor';
import { CommonService } from 'src/app/provider/common.service';
import { category } from '../user-management/dummy';
// import { dummy } from '../user-management/dummy';
declare var $

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.css']
})
export class CategoryManagementComponent implements OnInit {
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  page: any = 1
  totalItems: any;
  categoryList: any = [];
  // limit: any = 5;
  items = [];
  flip: boolean = false;
  isSearched: boolean = false;
  statusString: any;
  userId: any;
  status: any;
  constructor(
    private router: Router,
    public commonService: CommonService,
    // private datepipe: DatePipe
  ) {}

  ngOnInit(): void {

    this.searchFormValidation();
    this.getCategory();
  }

  // search form validation
  searchFormValidation() {
    this.searchForm = new FormGroup({
      'search': new FormControl(""),
    });
  }

  search() {
    if(this.searchForm.value.search) {
      this.getCategory();
    }
  }

  reset() {
    if (this.searchForm.value.search) {
      this.searchForm.reset();
      this.getCategory();
    }
  }

  // get all user list
  getCategory() {
    let apiReqBody = {
      "search": this.searchForm.value.search
    };
    let url = `admin/listCategory`;
    // console.log(url);
    this.commonService.showSpinner();
    this.commonService.postApi(url, apiReqBody, 1).subscribe(
      (res:any) => {
        if (res.responseCode == 200) {
          this.categoryList = res.result.docs;
          this.totalItems = res.result.total;
          console.log(this.categoryList);
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },(err:any)=>{
        this.totalItems=0
        this.categoryList=[]
       });
  }

  pagination(event) {
    this.currentPage = event;
    this.getCategory();
  }

  viewUser(id) {
    this.router.navigate(["/view-user"], {queryParams: {id: id,}, });
  }


  // navigate to view category with category id
  categoryView(id){
    this.router.navigate(["/view-category"], { queryParams: {Id: id,} });
  }

  // navigate to edit category with category id and category name
  categoryEdit(id,name){
    this.router.navigate(["/edit-category"], { queryParams: {Id: id, Name:name} });
  }

  // block category
  blockUnblockUser() {
    let url = "admin/block_UnblockUser";
    const body = {
      _id: this.userId,
    };
    this.commonService.showSpinner();
    this.commonService.putApi(url, body, 1).subscribe((res:any) => {
        if (res.statusCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getCategory();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },);
  }

  // delete modal
  deletModal(id){
    this.userId=id
    $('#deleteModal').modal('show')
  }


  // delete category
  deleteUser() {
    let url = `admin/deleteCategory?_id=${this.userId}`
    this.commonService.showSpinner()
    this.commonService.deleteApi(url,{},1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        this.getCategory()
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }

}
