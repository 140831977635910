import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgOtpInputModule } from "ng-otp-input";
import { CKEditorModule } from "ngx-ckeditor";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { ErrorInterceptor } from "../interceptor/error.interceptor";
import { AddFaqComponent } from "./add-faq/add-faq.component";
import { EditFaqComponent } from "./edit-faq/edit-faq.component";
import { FAQComponent } from "./faq.component";
import { FaqRoutingModule } from "./faq.routing.module";
import { ViewFaqComponent } from "./view-faq/view-faq.component";

@NgModule({
    declarations:[
        FAQComponent,
        EditFaqComponent,
        AddFaqComponent,
        ViewFaqComponent,
    ],

    imports:[
        CommonModule,
        CKEditorModule,
        FormsModule,
        ReactiveFormsModule,
        // BrowserAnimationsModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        HttpClientModule,
        NgOtpInputModule,
        ToastrModule.forRoot({
        positionClass: "toast-top-right",
        maxOpened: 1,
        preventDuplicates: true,
        }),
        FaqRoutingModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe],

})
export class FaqModule { }