import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-user-follower-view',
  templateUrl: './user-follower-view.component.html',
  styleUrls: ['./user-follower-view.component.css']
})
export class UserFollowerViewComponent implements OnInit {
  id: any;
  about: any;
  // dataa: any=[];
  userData: any;
  date=new Date()
  postId:any
  veiwfollowerId:any;
  constructor(public service:CommonService, public route: ActivatedRoute, public router:Router) { }

  ngOnInit() {
    // this.userData=[...category]
    this.route.queryParams.subscribe(x=>{this.postId= x['id']; })
    // this.getListCode();
    this.viewPost()
  }
// view perticular category 
  viewPost(){
    this.service.showSpinner()
    let url =  `admin/viewFollower?_id=${this.postId}`
    this.service.getApi(url,1).subscribe((res:any)=>{
     if(res.responseCode==200){
       this.veiwfollowerId=res.result
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
       this.service.hideSpinner()
       this.service.errorToast("data not found")
     }
    })
    }
    listfollower(id,tab) {
      this.router.navigate(["/user-management"], { queryParams: {Id: id,tab:tab} });
    }
  

}
