import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { dummy } from '../dummy';

@Component({
  selector: 'app-user-post-list',
  templateUrl: './user-post-list.component.html',
  styleUrls: ['./user-post-list.component.css']
})
export class UserPostListComponent implements OnInit {
  @Input() item = ''; 
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  customerData: any = [];
  today=new Date().toISOString().split('T')[0]
  
  
  // limit: any = 5;
  // items = [];
  // flip: boolean = false;
  isSearched: boolean = false;
  statusString: any;
  userId: any;
  status: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    // this.customerData=[...dummy]
    this.searchFormValidation();
    this.getAllUserList();
  }

  // search form validation
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }

  search() {
    if(this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getAllUserList();
    }
  }

  reset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset();
      this.getAllUserList();
    }
  }

  // get all user list
  getAllUserList() {
    let apiReqBody = {
      search: this.searchForm.value.search,
      fromDate:this.searchForm.value.fromDate,
      toDate:this.searchForm.value.toDate,
      page:this.currentPage,
      limit:this.itemsPerPage
    };
    // console.log(apiReqBody);
    let apiReqUrl = `admin/listUserPost?_id=${this.item}`;
    this.commonService.showSpinner();
    this.commonService.postApi(apiReqUrl, apiReqBody, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {  
          this.customerData = res.result.docs;
          this.totalItems = res.result.total;
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },(err:any)=>{
        if(err.responseCode==404){
          this.totalItems=0
          this.customerData=[]
        }
        else{
          this.customerData=[]
        }
      });
  }

  pagination(event) {
    this.currentPage = event;
    this.getAllUserList();
  }

  viewPost(id) {
    this.router.navigate(["/user-post-view"], { queryParams: {id: id},});
    console.log(id);
  }

}
