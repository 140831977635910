<!-- change password form start-->
<main class="middle-content">
<div class="content-section">
    <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
      <div class="  mb40">
        <div class="admin-profile">
          <h4 class="mb20 text-center">Change Password</h4>
        </div>
        <div class="add-store-block input-style mb20 mt20 ">
  
        <!-- form -->
        <form [formGroup]="changePasswordForm">
          <div class="form-group row align-items-baseline view-label-align" style="margin-bottom: 30px !important;">
            <label class="col-md-4 textalignright ">Old Password </label><span class="col-md-1">:</span>
            <div class="col-md-7 textaligncenter">
              <div class="form-group">
                <input type="password" class="form-control"  placeholder="Old password" formControlName="oldPassword" (keypress)="service.preventSpace($event)" maxlength="30">
                <div *ngIf="changePasswordForm.get('oldPassword').invalid && (changePasswordForm.get('oldPassword').touched || changePasswordForm.get('oldPassword').dirty)" class="text-danger">
                  <span *ngIf="changePasswordForm.get('oldPassword').hasError('required') ">*Please enter old password.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-baseline view-label-align">
            <label class="col-md-4 textalignright ">New Password</label><span class="col-md-1">:</span>
            <div class="col-md-7 textaligncenter">
              <div class="form-group">
                <input [type]="newPassword ? 'password' : 'text'" class="form-control" placeholder="New password" formControlName="newPassword" (keypress)="service.preventSpace($event)" maxlength="30">
                <div class="input-group-text">
                    <i (click)="newPassword = !newPassword" [class]="newPassword ? 'fa fa-eye' :'fa fa-eye-slash'"  aria-hidden="true" style="background-color: transparent;"></i>
                </div>
                <div *ngIf="changePasswordForm.get('newPassword').invalid && (changePasswordForm.get('newPassword').touched || changePasswordForm.get('newPassword').dirty)" class="text-danger">
                  <span *ngIf="changePasswordForm.get('newPassword').hasError('required')">*Please enter new password.</span>
                  <span *ngIf="changePasswordForm.get('newPassword').hasError('pattern')">
                    *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.(for example-Mobiloitte@1)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row align-items-baseline view-label-align">
            <label class="col-md-4 textalignright ">Confirm New Password</label><span class="col-md-1">:</span>
            <div class="col-md-7 textaligncenter">
              <div class="form-group">
                <input  [type]="confirmPassword ? 'password' : 'text'" class="form-control" placeholder="Confirm new password" formControlName="confirmPassword" (keypress)="service.preventSpace($event)" maxlength="30">
                <div class="input-group-text">
                  <i (click)="confirmPassword = !confirmPassword" [class]="confirmPassword ? 'fa fa-eye' :'fa fa-eye-slash'"  aria-hidden="true" style="background-color: transparent;"></i>
              <!-- <i *ngIf="confirmPassword == 'password'" class="fa fa-eye-slash" aria-hidden="true" (click)="viewConfirm(1)" style="background-color: transparent;"></i> -->
                </div>
                <div *ngIf="changePasswordForm.get('confirmPassword').dirty" class="text-danger">
                  <span *ngIf="changePasswordForm.get('confirmPassword').value != changePasswordForm.get('newPassword').value">*Password and confirm password must be match.</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="mt40 mb40 text-center ">
          <button class="btn btn-large  max-WT-150 btn-secondary" [disabled]="changePasswordForm.invalid" (click)="changePassword()">Change</button>
          <button class="btn btn-large  max-WT-150 btn-primary ml-2"  (click)="back()">Back</button>
        </div>
      </div>
    </div>
  </div>
</main>
  <!-- change password form end -->
  