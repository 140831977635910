import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { banner } from '../../user-management/dummy';

@Component({
  selector: 'app-view-banner',
  templateUrl: './view-banner.component.html',
  styleUrls: ['./view-banner.component.css']
})
export class ViewBannerComponent implements OnInit {
  id: any;
  about: any;
  userData: any=[];
  date=new Date()
  pagekey:any
  constructor(public service:CommonService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(x=>{this.pagekey= x['Id']; })
    this.viewBanner()
  }

  viewUserData(){
    this.userData=this.userData.find(h=>h.id==this.pagekey)
  }

  // view perticular banner
  viewBanner(){
    let url = `admin/viewBanner?bannerId=${this.pagekey}`
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.userData = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    })
  }



}
