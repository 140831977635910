<!-- <p>user-list works! {{item}}</p> -->
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Banner Management</h1>
    </div>
    <div class="content-section">
    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">

                        <div class="tab-pane1">
                                    <div class="row align-items-center">
                                        <div class="col-md-12">
                                            <div class="text-center admin-btn mb-4 mt10">
                                                <button type="button" class="btn btn-theme" style="margin-left: auto;" routerLink="/add-banner" >Add Banner</button>
                                            </div> 
                                        </div>
                                    </div>
                        </div>
                        <div class="table-responsive" style="margin-bottom: 30px;">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                <thead class="thead">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img heading1">S.No</th>
                                        <th scope="col" class="Sorting-img">Banner Image</th>
                                        <th scope="col" class="Sorting-img">Banner Content</th>
                                        <th scope="col" class="Sorting-img">Created Date & Time</th>
                                        <th scope="col" class="Sorting-img">Status</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img heading2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let item of bannerList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index">
                                        <td>{{i+1+(currentPage-1)*itemsPerPage}}</td>
                                        <td class="content"><img [src]="item.bannerImage" alt="image" style="height:40px;"></td>
                                        <td class="content"><img>{{(item.bannerTitle  | titlecase ) || '--' }}</td>
                                        <td class="content">{{item.createdAt | date:'medium'}}</td>
                                        <td class="content">{{(item.status| titlecase) || '--' }}</td>
                                        <!-- <td class="content" *ngIf="item.isActive==false">Block</td> -->
                                        <td class="justify-content-center d-flex">
                                            <button class="btn btn-info ml-2 bt-margin bth" title="View" (click)="bannerView(item._id)">
                                                <em class="fa fa-eye" aria-hidden="true"></em>
                                            </button>
                                            <button class="btn btn-gray ml-2 bth" title="edit" (click)="bannerEdit(item._id)">
                                                <em class="fa fa-edit" aria-hidden="true"></em>
                                            </button>
                                            <button class="btn btn-danger ml-2 bth" title="Delete" data-toggle="modal" (click)="deletModal(item._id)">
                                                <em class="fa fa-trash" aria-hidden="true"></em>
                                            </button>
                                            <label class="switch" style="top: 6px;">
                                                <input type="checkbox" *ngIf="item.status=='ACTIVE' " checked (click)="blockUnblockUser(item._id)" >
                                                <input type="checkbox" *ngIf="item.status=='BLOCK' " (click)="blockUnblockUser(item._id)" >
                                                <span class="slider round"></span>
                                            </label> 
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="!bannerList.length">
                                        <td colspan="7">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt-2 text-align-end" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
            </div>
        </div>
    </div>
</div>
</main>
<!-- delete user-management modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
<div class="modal-dialog max-WT-500">
<form class="change_password">
    <div class="modal-content">
        <div class="modal-header d-block text-center modal-header-custm">
            <h5 class="modal-title d-inline-block">Delete</h5>
        </div>
        <div class="modal-inner-content">
            <div class="modal-body">
                <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                    <div class="w-100">
                        <p>Are you sure you want to delete this user?</p>
                        <div class="modal-btn-box">
                            <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="deleteUser()">Yes</button>
                            <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div>
</div>
<div class="modal fade global-modal reset-modal" id="BlockModal">
<div class="modal-dialog max-WT-500">
<form class="change_password">
    <div class="modal-content">
        <div class="modal-header d-block text-center modal-header-custm">
            <h5 class="modal-title d-inline-block">{{statusString}}</h5>
        </div>
        <div class="modal-inner-content">
            <div class="modal-body">
                <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                    <div class="w-100">
                        <p>Are you sure you want to {{statusString | lowercase}} this user?</p>
                        <div class="modal-btn-box">
                            <button type="submit" class="btn btn-primary" data-dismiss="modal" >Yes</button>
                            <button type="button" class="btn btn-secondary  ml-2" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div>
</div>
<div class="container">
<!-- Trigger the modal with a button -->
<!-- Modal -->
<div class="modal fade" id="myModal-export" role="dialog">
<div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
        <div class="modal-header" style="background: goldenrod;">
            <h5 class="modal-title">Confirmation</h5>
        </div>
        <div class="modal-body">
            <p>The EXCEL export file will be generated for download.</p>
            <p>Disable any popup blockers in your browser to ensure proper download.</p>
            <p>Ok to proceed?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success">OK</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
    </div>
</div>
</div>
</div>

