import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { banner } from '../../user-management/dummy';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.css']
})
export class AddBannerComponent implements OnInit {
  id: any;
  about: any;
  data: any=[];
  date=new Date()
  pagekey:any
  listArray:any=[];
  arrayId: number;
  bannerTitle:any;
  bannerImage:any
  isEdit : boolean = false
  image: any;
  addBannerForm:FormGroup;
  constructor(public service:CommonService, public route: ActivatedRoute,public router:Router) { 

    
  }

  ngOnInit() {
    this.addBannerForm = new FormGroup({
      'bannerTitle':new FormControl("",Validators.required)
    })
    // this.getListCode();
  }
  
  // upload banner
  uploadFile($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var image = new FormData();
    image.append('uploaded_file', img)
    var apiReqUrl="admin/uploadFile"
    this.service.showSpinner();
    this.service.postFormDataApi(apiReqUrl,image,1).subscribe((res:any) => {
      this.service.hideSpinner();
      if (res['responseCode'] == '200') {
        this.bannerImage = res['result'].mediaUrl;
        console.log(this.bannerImage);
        
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage);
      }
      else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    })
  }

  

  // ------------ Api of add banner ---------//
  addBanner(){
    let url = `admin/addBanner`
    let data = {
      'bannerTitle':this.addBannerForm.value.bannerTitle,
      'bannerImage':this.bannerImage
    }
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        this.router.navigate(['/banner-management'])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    })
  }

  

  
}
