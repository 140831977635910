<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Support</h1>
    </div>
    <div class="content-section">
    <div class="outer-box">
        <div class="global-table no-radius p0">
             <div class="tab-content1">


                <div class="tab-pane1">
                    <div class="sec_head_new sec_head_new_after">

                        <form [formGroup]="searchForm">
                            <div class="row align-items-center">
                                <div class="col-md-4 pr-0">
                                    <div class="filter_search mb20 width100">
                                        <label class="d-flex align-items-center">Search:</label>
                                        <div class="input-group filter_search_group">
                                            <input type="text" class="form-control overflow-text" formControlName="search" placeholder="Search by email" maxlength="60">
                                            <div class="input-group-append">
                                                <button class="btn btn_search_group"><img src="assets/img/icon-search.png" (click)="search()"  alt="Search"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="text-center admin-btn mb2 mt10">
                                        <button type="button" class="btn btn-theme" (click)="search()">Search</button>
                                        <button type="button" class="btn btn-theme" (click)="reset()">Reset</button>
                                    </div>
                                       
                                </div>

                            </div>
                        </form>
                    </div>
                </div>



                        <div class="table-responsive" style="margin-bottom: 60px;">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                <thead class="thead">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img heading1">S.No</th>
                                        <th scope="col" class="Sorting-img">Name</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img heading2">Email Address</th>
                                        <th scope="col" class="Sorting-img">Description</th>
                                        <th scope="col" class="Sorting-img">Created Date & Time</th>
                                        <th scope="col" class="Sorting-img">Updated Date & Time</th>
                                        <th scope="col" class="Sorting-img">Status</th>
                                        <th scope="col" class="Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let item of contentList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total}; let i = index">
                                        <td>{{i+1+(currentPage-1)*itemsPerPage}}</td>
                                        <td class="content">{{item?.userName}}</td>
                                        <td class="content">{{item?.userEmail}}</td>
                                        <td class="content">{{item?.comment}}</td>
                                        <td class="content">{{item?.createdAt | date:'medium'}}</td>
                                        <td class="content">{{item?.updatedAt | date:'medium'}}</td>
                                        <td class="content">
                                            <select name="" id="" [value]="item?.responseStatus" class="form-control" (change)="updateStatus(item._id,item?.responseStatus)">
                                                <option value="AWAIT">Pending</option>
                                                <option value="RESPONDED">Responded</option>
                                            </select>
                                        </td>
                                        <td class="content">
                                            <button  class="btn btn-info ml-2 bt-margin bth" title="View" (click)="viewSupport(item._id)">
                                                <em class="fa fa-eye" aria-hidden="true"></em>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="text-center" *ngIf="!contentList?.length">
                                        <td colspan="8">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    <div class="custom-pagination mt-2 text-align-end" >
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div>
            </div>
        </div>
    </div>
</div>
</main>