import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { category } from '../../user-management/dummy';


@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  id: any;   
  about: any;
  data: any=[];
  date=new Date()
  pagekey:any
  listArray=category
  arrayId: number;
  categoryName:any
  image:any;
  addCategoryForm:FormGroup;

  constructor(public commonService:CommonService, public route: ActivatedRoute,public router:Router) { }

  ngOnInit() {
  this.addCategoryForm = new FormGroup({
    'categoryName':new FormControl("",Validators.required)
  })
    this.route.params.subscribe(x=>{
      this.pagekey= x['id'];
      this.arrayId=this.listArray.length
    })
  }

  // upload image in category
  uploadFile($event) {
    var img = $event.target.files[0];
    this.image=img.name
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var image = new FormData();
    image.append('uploaded_file', img)
    var apiReqUrl="admin/uploadFile"
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(apiReqUrl,image,1).subscribe((res:any) => {
      this.commonService.hideSpinner();
      if (res['responseCode'] == '200') {
        this.image = res['result'].mediaUrl
        this.commonService.hideSpinner();
        this.commonService.successToast(res.responseMessage);
      }
      else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.responseMessage);
      }
    })
  }


  // add new category
  addCategory(){
    let data = {
      'categoryName':this.addCategoryForm.value.categoryName,
      'image':this.image
    }
    let url = `admin/addCategory`
    this.commonService.showSpinner()
    this.commonService.postApi(url,data,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
        this.router.navigate(['/category-management'])
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }


}
