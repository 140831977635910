import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/auth.guard";
import { AddCategoryComponent } from "./add-category/add-category.component";
import { CategoryManagementComponent } from "./category-management.component";
import { EditCategoryComponent } from "./edit-category/edit-category.component";
import { ViewCategoryComponent } from "./view-category/view-category.component";

const routes: Routes = [
    { path: "category-management",component:CategoryManagementComponent,canActivate:[AuthGuard]},
    { path: "add-category",component:AddCategoryComponent,canActivate:[AuthGuard]},
    { path: "edit-category",component:EditCategoryComponent,canActivate:[AuthGuard]},
    { path: "view-category",component:ViewCategoryComponent,canActivate:[AuthGuard]},
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CategoryRoutingModule { }