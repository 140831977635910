import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
// import { dummy } from '../user-management/dummy';

declare var $;
@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css']
})
export class StaticContentComponent implements OnInit {
  staticContentList: any = [];
  userId: any;
  constructor(
    private router: Router,
    public commonService: CommonService,
    // private datepipe: DatePipe
  ) {}

  ngOnInit(): void {

    this.getStaticContentList();
  }


  staticView(view,id){
    if(view=='contact_Us'){
      this.router.navigate(["/contact-view"], { queryParams: {id: id} });  
    }
    else{
      this.router.navigate(["/static-view"], { queryParams: {data: view} });
      
    }
    
  }

  // get all static content list
  getStaticContentList() {
    let url = `static/staticContentList`;
    console.log(url);
    this.commonService.showSpinner();
    this.commonService.getApi(url, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {
          this.staticContentList = res.result;
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
    );
  }

}
