import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-user-followings',
  templateUrl: './user-followings.component.html',
  styleUrls: ['./user-followings.component.css']
})
export class UserFollowingsComponent implements OnInit {
  @Input() UserPostList = ''; 
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  customerData: any = [];
  today=new Date().toISOString().split('T')[0]
  
  
  // limit: any = 5;
  items = [];
  flip: boolean = false;
  isSearched: boolean = false;
  statusString: any;
  userId: any;
  status: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    // private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.searchFormValidation();
    this.getAllUserList();
  }

  // search form validation
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }

  search() {
    if(this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getAllUserList();
    }
  }

  reset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset();
      this.getAllUserList();
    }
  }

  // get all user list
  getAllUserList() {
    let apiReqBody = {
      fromDate:this.searchForm.value.fromDate,
      toDate:this.searchForm.value.toDate,
      page:this.currentPage,
      limit:this.itemsPerPage
    };
    let url = `admin/listFollowing?_id=${this.UserPostList}`;
    this.commonService.showSpinner();
    this.commonService.postApi(url, apiReqBody, 1).subscribe((res:any) => {
        if (res.responseCode == 200) {
          this.customerData = res.result.docs;
          this.totalItems = res.result.total;
          // console.log(this.customerData);
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },(err:any)=>{
        if(err.responseCode==404){
          this.totalItems=0
          this.customerData=[]
        }
        else{
          this.customerData=[]
        }
      });
  }

  pagination(event) {
    this.currentPage = event;
    this.getAllUserList();
  }

  getFollowingId(id) {
    this.router.navigate(["/user-following-view"], {
      queryParams: {
        id: id,
      },
    });
  }



  blockUnblockUser() {
    let url = "admin/block_UnblockUser";
    const body = {
      _id: this.userId,
    };
    this.commonService.hideSpinner();
    this.commonService.putApi(url, body, 1).subscribe(
      (res:any) => {
        if (res.statusCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getAllUserList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast(err);
      }
    );
  }

  deleteUser() {
    let url = "admin/deleteUser?userid=" + this.userId;
    const body = {
      blockedUserId: this.userId,
    };
    this.commonService.hideSpinner();
    this.commonService.deleteApi(url, body, 1).subscribe(
      (res:any) => {
        if (res.status == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.message);
          this.getAllUserList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.message);
        }
      },
      (err) => {
        this.commonService.hideSpinner();
        this.commonService.errorToast("Data not found");
      }
    );
  }
  exportAsXLSX() {
    // let dataArr = [];
    // this.customerData.forEach((element, ind) => {
    //   let obj = {};
    //   obj = {
    //     "S no": ind + 1,
    //     Name: element.firstName
    //       ? element.firstName + " " + element?.lastName
    //       : "N/A",
    //     Email: element.email ? element.email : "N/A",
    //     "Phone No": element.phoneNo ? element.phoneNo : "N/A",
    //     "Created At": element.createTime
    //       ? this.datepipe.transform(element.createTime, "dd/MM/yyyy, hh:mm a")
    //       : "N/A",
    //     Status: element.status ? element.status : "N/A",
    //   };
    //   dataArr.push(obj);
    // });
    // this.commonService.exportAsExcelFile(dataArr, "User Management");
  }
}
