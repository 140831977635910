<div>
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Dashboard</h1>
            <hr>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list1 d-flex w-100 flex-wrap ">
                <li class="dashbord-box" style="background-color: rgb(249 48 123 / 88%);">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalRegisteredUser || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Registered User</h6>
                    <div>
                        <em class="fa fa-user" style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15); padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">
                        <a routerLink="/user-list" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
                <li class="dashbord-box" style=" background-color: #f96d38;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalUserPost || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Number of Post</h6> 
                    <div>
                        <em class="fa fa-image" style="float: right;font-size: 90px;margin-top: -60px;color: rgba(0, 0, 0, 0.15); padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">
                        <a routerLink="/user-post-list" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
                <li class="dashbord-box transaction" style="background-color:rgb(236 145 9 / 88%) ;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalBlockPost || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Blocked Post</h6>
                    <div>
                        <em class="fa fa-ban transaction-icon" style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15); padding-right: 10px;"></em>
                    </div>
                    <div class="transaction-info">
                        <a routerLink="/user-post-list" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
                <li class="dashbord-box" style="background-color: #d9c328;">
                    <h4 style="color:white;padding-left: 15px;padding-right: 15px;">{{totalCategory || 0}}</h4>
                    <h6 style="color: white;padding-left: 15px;padding-right: 15px;">Total Number of Category</h6>
                    <div>
                        <em class="fa fa-list-alt" style="float: right;font-size: 90px;margin-top: -50px;color: rgba(0, 0, 0, 0.15);padding-right: 10px;"></em>
                    </div>
                    <div style="margin-top: 48px;">
                        <a routerLink="/category-management" class="small-box-footer">More info <em class="fa fa-arrow-circle-right"></em></a>
                    </div>
                </li>
            </ul>
        </div>
    </main>
</div>
