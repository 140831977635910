import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {

  form: FormGroup;
  faqList: any;
  faqId: any;
  ques: any;
  ans : any;
  regex ="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$"
  constructor(private activatedroute: ActivatedRoute, public service: CommonService,private router : Router) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.id;

    });
  }

  ngOnInit() {

    this.form = new FormGroup({
      "question": new FormControl('',[ Validators.required]),
      "answer": new FormControl('',[ Validators.required]),
    });
    this.viewFaq();
  }

  updateFaq(){
    let url = 'static/editFaq?_id=' + this.faqId
    const data = {
      question : this.form.get('question').value,
      answer : this.form.get('answer').value
    }
    this.service.showSpinner()
    this.service.putApi(url,data,1).subscribe((res:any) => {
      if(res.responseCode == 200){
        this.service.successToast(res.responseMessage)
        this.service.hideSpinner()
        this.router.navigate(['/faq'])
      }
      else{
        this.service.errorToast(res.responseMessage)
        this.service.hideSpinner()
      }
    })
  }

  
 viewFaq(){
  let url = 'static/viewFaq?_id='+this.faqId
  this.service.showSpinner()
  this.service.getApi(url,1).subscribe((res:any) =>{
    if(res.responseCode == 200){
      this.ques = res.result.question
      this.ans = res.result.answer
      this.form.patchValue({
        "question" : res.result.question,
        "answer" : res.result.answer
      })
      //this.date = res.result.updatedAt
      this.service.successToast(res.responseMessage)
      this.service.hideSpinner()
    }
    else{
     this.service.successToast(res.responseMessage)
     this.service.hideSpinner()
    }
  })
}




}
