import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-user-posts-list',
  templateUrl: './user-posts-list.component.html',
  styleUrls: ['./user-posts-list.component.css']
})
export class UserPostsListComponent implements OnInit {
 // @Input() item = ''; 
 searchForm: FormGroup;
 itemsPerPage = 10;
 currentPage = 1;
 totalItems: any;
 customerDataExcel:any=[]
 customerData: any = [];
 statusString: any;
 userId: any;
 status: any;
 today=new Date().toISOString().split('T')[0]
 constructor(
   private router: Router,
   public commonService: CommonService,
   private datepipe: DatePipe
 ) {
 }

 ngOnInit(): void {
   this.searchFormValidation();
   this.getAllUserList();
   
 }

 // search form validation
 searchFormValidation() {
  this.searchForm = new FormGroup({
    search: new FormControl(""),
    fromDate: new FormControl(""),
    toDate: new FormControl(""),
  });
 }

 search() {
  if(this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
    this.getAllUserList();
  }
}

reset() {
  if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
    this.searchForm.reset();
    this.getAllUserList();
  }
} 
 getAllUserList() {
   let apiReqBody = {
     'search': this.searchForm.value.search,
     'fromDate':this.searchForm.value.toDate,
     'toDate':this.searchForm.value.fromDate,
     'page':this.currentPage,
     'limit':this.itemsPerPage
   };
   let apiReqUrl=`admin/listAllPost`
   
   this.commonService.showSpinner();
   this.commonService.postApi(apiReqUrl, apiReqBody, 1).subscribe((res:any) => {
       if (res.responseCode == 200) {
         this.customerData = res.result.docs;

         this.totalItems = res.result.total;
         this.commonService.hideSpinner();
         this.commonService.successToast(res.responseMessage);
       } else {
         this.commonService.hideSpinner();
         this.commonService.errorToast(res.responseMessage);
       }
     },(err:any)=>{
      this.totalItems=0
      this.customerData=[]
     }
   );
 }

 pagination(event) {
   
   this.currentPage = event;
   this.getAllUserList();
 }

 viewUser(id) {
   this.router.navigate(["/user-post-list-view"], { queryParams: {id: id} });
 }

 getUserId(id,status) {
   this.userId = id;
   this.status=status
   if (this.status == "ACTIVE") {
     this.statusString = "BLOCK";
   } 
   else {
     this.statusString = "UNBLOCK";
   }
 }

 blockUnblockUser() {
   let apiReqUrl = `admin/blockUnblockPost?postId=${this.userId}`;
   this.commonService.showSpinner();
   this.commonService.putApi(apiReqUrl,{}, 1).subscribe((res:any) => {
       if (res.responseCode == 200) {
         this.commonService.hideSpinner();
         this.commonService.successToast(res.responseMessage);
         this.getAllUserList();
       } else {
         this.commonService.hideSpinner();
         this.commonService.errorToast(res.responseMessage);
       }
     },
   );
 }

 deleteUser() {
   let apiReqUrl = "admin/deletePost?postId=" + this.userId;
   this.commonService.hideSpinner();
   this.commonService.deleteApi(apiReqUrl,{}, 1).subscribe((res:any) => {
       if (res.responseCode == 200) {
         this.commonService.hideSpinner();
         this.commonService.successToast(res.responseMessage);
         this.getAllUserList();
       } else {
         this.commonService.hideSpinner();
         this.commonService.errorToast(res.responseMessage);
       }
     });
 }
 exportAsXLSX() {
  let apiReqBody = {
    search: this.searchForm.value.search,
    fromDate: this.searchForm.value.fromDate,
    toDate:this.searchForm.value.toDate,
    limit:this.totalItems
  };
  let apiReqUrl=`admin/listAllPost`
  this.commonService.postApi(apiReqUrl, apiReqBody, 1).subscribe((res:any) => {
  if (res.responseCode == 200) {
  this.customerDataExcel = res.result.docs;
   let dataArr = [];
   this.customerDataExcel.forEach((element, ind) => {
     let obj = {};
     obj = {
       "S.No": ind + 1,
       "Name": element.userDetails.fullName? element.userDetails.fullName:"--",
       "Username": element.userDetails.userName? element.userDetails.userName : "--",
       "Email": element.userDetails.email ? element.userDetails.email : "--",
       "Phone Number": element.userDetails.phoneNumber ? element.userDetails.phoneNumber : "--",
       "Created Date & Time": element.createdAt? this.datepipe.transform(element.createdAt, "dd/MM/yyyy, hh:mm a"): "--",
       "Status": element.status ? element.status : "--",
     };
     dataArr.push(obj);
   });
   this.commonService.exportAsExcelFile(dataArr, "User Post Management");
  }
})
 }
}
