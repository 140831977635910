import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { banner } from '../user-management/dummy';


declare var $;
@Component({
  selector: 'app-banner-management',
  templateUrl: './banner-management.component.html',
  styleUrls: ['./banner-management.component.css']
})
export class BannerManagementComponent implements OnInit {
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  bannerList: any = [];
  // limit: any = 5;
  items = [];
  flip: boolean = false;
  isSearched: boolean = false;
  statusString: any;
  userId: any;
  status: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    // private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    // this.customerData=[...banner]
    this.getbannerList();
  }


  // get banner list
  getbannerList(){
    let apiReqUrl = `admin/listBanner`;
    let apiReqData={
      page:this.currentPage,
      limit:this.itemsPerPage
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl,apiReqData,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.bannerList = res.result.docs
        this.totalItems = res.result.total
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }



  pagination(event) {
    this.currentPage = event;
    this.getbannerList();
  }

  viewUser(id) {
    this.router.navigate(["/view-user"], {
      queryParams: {
        id: id,
      },
    });
  }

  getUserId(id, status) {
    this.userId = id;
    this.status=status
    if (this.status == "ACTIVE") {
      this.statusString = "BLOCK";
    } 
    else {
      this.statusString = "UNBLOCK";
    }
  }
  bannerView(id){
    this.router.navigate(["/view-banner"], { queryParams: {Id: id,} });
  }
  bannerEdit(id){
    this.router.navigate(["/edit-banner"], { queryParams: {Id: id} });
  }
  blockUnblockUser(id) {

    let url = `admin/enableDisable?bannerId=${id}`;
    this.commonService.hideSpinner();
    this.commonService.putApi(url, {}, 1).subscribe(
      (res:any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getbannerList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }

  // delete modal show and send banner id
  deletModal(id){
    this.userId=id
    $('#deleteModal').modal('show')
  }

  deleteUser() {
    let url = `admin/removeBanner?bannerId=${this.userId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(url, {}, 1).subscribe(
      (res:any) => {
        if (res.responseCode == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getbannerList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }
}
