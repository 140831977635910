import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  totalRegisteredUser: any;
  totalBlockPost: any;
  totalUserPost: any;
  totalCategory: any;

  constructor(private commonService:CommonService, private router:Router) { }

  ngOnInit(): void {
    this.dashboard()
  }

  dashboard(){
    let apiReqUrl="admin/adminDashboard"
    this.commonService.showSpinner()
    this.commonService.getApi(apiReqUrl,1).subscribe((res:any)=>{
      if(res.responseCode == 200){
        this.totalRegisteredUser = res.result.totalRegisteredUser
        this.totalBlockPost = res.result.totalBlockPost
        this.totalUserPost = res.result.totalUserPost
        this.totalCategory = res.result.totalCategory
        this.commonService.hideSpinner()
        this.commonService.successToast(res.responseMessage)
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }


}
