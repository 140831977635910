import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { category } from '../../user-management/dummy';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
  id: any;
  about: any;
  dataArry :any=[];
  date=new Date()
  pagekey:any;
  image:any;
  categoryName: any;
  editCategoryForm:FormGroup
  constructor(public service:CommonService, public route: ActivatedRoute,public router:Router) { }

  ngOnInit() {
    this.editCategoryForm = new FormGroup({
      'categoryName':new FormControl("",Validators.required)
    })
    this.route.queryParams.subscribe(x=>{
      this.pagekey= x.Id
    })
    this.viewCategory()
  }

  viewCategory(){
    this.service.showSpinner()
    let url =  `admin/viewCategory?_id=${this.pagekey}`
    this.service.getApi(url,1).subscribe((res:any)=>{
     if(res.responseCode==200){
       this.id=res.result._id
       this.categoryName = res.result.categoryName
       this.image = res.result.image
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
       this.service.hideSpinner()
       this.service.errorToast("data not found")
     }
    })
    }

  // Get List Code by admin
  getEditCategory(){
    let categoryname ={
      'categoryName':this.editCategoryForm.value.categoryName,
      'image':this.image
    }
    this.service.showSpinner();
    let url = `admin/editCategory?_id=${this.pagekey}`
    this.service.putApi(url,categoryname,1).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.responseCode==200){
        this.dataArry = res.result
        this.categoryName = res.result.categoryName
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        this.router.navigate(['/category-management'])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast("Data not found")
      }
    })
  }
// upload image in category
uploadFile($event): void {
  var img = $event.target.files[0];
  this.uploadImageFunc(img);
}

uploadImageFunc(img) {
  var image = new FormData();
  image.append('uploaded_file', img)
  var apiReqUrl="admin/uploadFile"
  this.service.showSpinner();
  this.service.postFormDataApi(apiReqUrl,image,1).subscribe((res:any) => {
    this.service.hideSpinner();
    if (res['responseCode'] == '200') {
      this.image = res['result'].mediaUrl;
      this.service.hideSpinner();
      this.service.successToast(res.responseMessage);
    }
    else {
      this.service.hideSpinner();
      this.service.errorToast(res.responseMessage);
    }
  })
}


}
