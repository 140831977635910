<div class="wrapper">
    <main class="middle-content">

        <div *ngIf="currTab=='General'">
            <div class="page_title_block">
                <h1 class="page_title">General Information</h1>
            </div>
        </div>
        <div *ngIf="currTab=='user-list'">
            <div class="page_title_block">
                <h1 class="page_title">User Posts</h1>
            </div>
        </div>
        <div *ngIf="currTab=='user-follower'">
            <div class="page_title_block">
                <h1 class="page_title">User Followers</h1>
            </div>
        </div>
        <div *ngIf="currTab=='user-following'">
            <div class="page_title_block">
                <h1 class="page_title">User Followings</h1>
            </div>
        </div>
        <div class="content-section">
      
            <div class="custom_tabs common-tabs">
                <div class="row mb20 justify-content-center">
                    <div class="col-sm-12">
                        <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                    [ngClass]="{'active': currTab=='General'}" (click)="selectTab('General')"
                                    href="javascript:;">GENERAL INFORMATION</a>
                            </li>
                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                    [ngClass]="{'active': currTab=='user-list'}" (click)="selectTab('user-list')"
                                    href="javascript:;">USER POSTS</a>
                            </li>
                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='user-follower'}" (click)="selectTab('user-follower')"
                                href="javascript:;">USER FOLLOWERS</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                            [ngClass]="{'active': currTab=='user-following'}" (click)="selectTab('user-following')"
                            href="javascript:;">USER FOLLOWINGS</a>
                       </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div *ngIf="currTab=='General'">
                            <app-user-general-information [item]="userId"></app-user-general-information>
                        </div>
                        <div *ngIf="currTab=='user-list'">
                            <app-user-post-list [item]="userId"></app-user-post-list>
                        </div>
                        <div *ngIf="currTab=='user-follower'">
                            <app-user-followers [UserPostList]="userId"></app-user-followers>
                        </div>
                        <div *ngIf="currTab=='user-following'">
                            <app-user-followings [UserPostList]="userId"></app-user-followings>
                        </div>
                    </div>          
                </div>
            </div>
        </div>
    </main>
</div>
