import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.css']
})
export class AddFaqComponent implements OnInit {
  form: FormGroup;
  regEx = "^(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,5}$"
  constructor(private router: Router, public service: CommonService) { }

  ngOnInit() {
    this.form = new FormGroup({
      "question": new FormControl('',[ Validators.required]),
      "answer": new FormControl('',[ Validators.required]),
    });
  }

  // add faq
  addFaq() {
    let url = 'static/addFaq'
    const data = {
      question : this.form.get('question').value,
      answer : this.form.get('answer').value
    }
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res:any) => {
      if(res.responseCode == 200){
        this.service.successToast(res.responseMessage)
        this.service.hideSpinner();
        this.router.navigate(['/faq']);
      }
      else{
        this.service.successToast(res.responseMessage)
        this.service.hideSpinner();
      }
    })
}
}
