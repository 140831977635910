import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $:any
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  otp:number
  email: any = atob(localStorage.getItem("email"));
  constructor(public commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.resetPassword = new FormGroup({
      "password": new FormControl('', ([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)])),
      "confirmPassword": new FormControl('', [Validators.required])
    });
  }
 
  // -------------- Api of reset password-----------//
  resetPasswordApi() {
    let apiReqUrl = "admin/resetPassword"
    let apiReqData = {
      "email": this.email,
      "newPassword": this.resetPassword.value.confirmPassword,
    }
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl,apiReqData,0).subscribe((res:any)=>{
      if(res.responseCode == 200){
        this.commonService.hideSpinner()
        localStorage.removeItem('email')
        this.router.navigate(['/login'])
        this.commonService.successToast(res.responseMessage)
      }
      else{
        this.commonService.hideSpinner()
        this.commonService.errorToast(res.responseMessage)
      }
    })
  }


  onOtpChange(e) {
    this.otp = e;
  }
  
  // to hide modal
  hideModal(){
    $('#exampleModal').modal('hide')
  }
  // to open modal  
  openModal(){
    $('#exampleModal').modal('show')
  }


}
