import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custom'
})
export class CustomPipe implements PipeTransform {

  transform(value:any, ...args:any[]):any {
    console.log(value);
    if(value=='contact_Us'){
    return "Contact Us";
    }
    else if(value=='terms_Of_Services'){
      return "Terms & Conditions";
    }
    else if(value=='privacy_Policy'){
      return "Privacy Policy";
    }
    else if(value=='about_Us'){
      return "About Us";
    }
    else{
      return "--";
    }
  }

}
