
   <div class="wrapper">
    <main class="middle-content">
      <div class="page_title_block">
        <h1 class="page_title"><i class="fa fa-question-circle" aria-hidden="true"></i>
            FAQ'S / Edit</h1>
    </div>
  
        <div class="content-section">
          <div class="order-view max-WT setting-page">
            <form [formGroup]="form">
            <div class="mb40">
              <div class="add-store-block input-style mb20 ">
                <div class="form-group row  view-label-align">
                  <label class="col-md-3 textalignright ">Question
                   </label> <span class="col-md-1">:</span>
                    <div class="col-md-8">
                        <input type="text" class="form-control" maxlength="100"
                        formControlName="question">
                    <div *ngIf="form.get('question').invalid && (form.get('question').dirty || form.get('question').touched)" class="text-danger">
                        <span *ngIf="form.get('question').hasError('required')"> *Please enter question.</span>
                        <!-- <span *ngIf="form.get('question').hasError('pattern')"> *Please enter valid question.</span> -->
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-3 textalignright ">Answer
                    </label><span class="col-md-1">:</span>
                    <div class="col-md-8">
                    <textarea class="form-control" formControlName="answer" name="" id="" cols="30" rows="5" maxlength="300"
                      (keypress)="service.preventSpace($event)" ></textarea> 
                     
                 
                    <div *ngIf="form.get('answer').invalid && (form.get('answer').dirty || form.get('answer').touched)" class="text-danger">
                     
                      <span *ngIf="form.get('answer').hasError('required')"> *Please enter answer.</span>
                  </div>

  
                  </div>
                </div>
              </div>
              <div class="text-center mt40 mb40" >
                <button class="btn btn-theme  max-WT-150 btn-secondary" 
                  routerLink="/faq" >Back</button>
                  <button class="btn btn-theme  max-WT-150 btn-primary ml-2" 
                  [disabled]="!form.valid" (click) = "updateFaq()">Update</button>
              </div>
          
            </div>
            </form>
          </div>
        </div>
    </main>
  </div>