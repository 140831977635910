
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content" >
        <!-- Page Title Start -->
 
        <!-- Page Title End -->
        <div class="content-section">
            <div class="page_title_block">
                <h1  class="page_title">{{title| custom}}</h1>
            </div>
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block1 input-style">
                        <div class="form-group row ">
                            <label class="col-md-3" >Page Name </label>
                            <label class="col-md-1"> : </label>
                            <div class="col-md-8" style="margin-top: -15px;max-height: 30px;">
                                <textarea class="form-control1 common-textarea" readonly [value]="title | custom"></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-3">Description</label>
                            <label class="col-md-1"> : </label>
                            <div class="col-md-8">
                                <ck-editor name="data" skin="moono-lisa" language="en" [fullPage]="true" [(ngModel)]="data"></ck-editor>
                            </div>
                        </div>
                        <div class="text-center mt40">
                            <button href="javascript:void(0)" class="btn btn-theme  max-WT-200 font-100 btn-green mr-2" (click)="updateStaticContent()" >Update</button>
                            <button href="javascript:void(0)" class="btn btn-theme  max-WT-200 font-100 btn-grey ml5" [routerLink]="['/static']">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
