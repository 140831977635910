import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { UserFollowerViewComponent } from './user-follower-view/user-follower-view.component';
import { UserFollowingViewComponent } from './user-following-view/user-following-view.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserManagementComponent } from './user-management.component';
import { UserPostViewComponent } from './user-post-view/user-post-view.component';

const routes: Routes = [
  { path: "user-management", component: UserManagementComponent,canActivate:[AuthGuard]},
  { path: "user-post-view",component:UserPostViewComponent,canActivate:[AuthGuard]},
  { path: "user-list",component:UserListComponent,canActivate:[AuthGuard]},
  { path: "user-follower-view",component:UserFollowerViewComponent,canActivate:[AuthGuard]},
  { path: "user-following-view",component:UserFollowingViewComponent,canActivate:[AuthGuard]},
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserManagementRoutingModule { }