import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-banner',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.css']
})
export class EditBannerComponent implements OnInit {
  pagekey: any;
  listArray:any=[];
  bannerImage:any;
  editBannerForm:FormGroup
  constructor(private service:CommonService,public route: ActivatedRoute,public router:Router) { 
    this.route.queryParams.subscribe(x=>{
      this.pagekey= x.Id;
    })
  }

  ngOnInit(): void {
    this.editBannerForm = new FormGroup({
      'bannerTitle':new FormControl("",Validators.required)
    })
    this.getBanner()
  }


  // --------- api of view banner -----------//
  getBanner(){
    let url = `admin/viewBanner?bannerId=${this.pagekey}`
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.listArray = res.result
        this.bannerImage =res.result.bannerImage;
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    })
  }


  // ---------- api of upload banner ------------//
  uploadFile($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var image = new FormData();
    image.append('uploaded_file', img)
    var apiReqUrl="admin/uploadFile"
    this.service.showSpinner();
    this.service.postFormDataApi(apiReqUrl,image,1).subscribe((res:any) => {
      this.service.hideSpinner();
      if (res['responseCode'] == '200') {
        this.bannerImage = res['result'].mediaUrl;
        console.log(this.bannerImage);
        
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage);
      }
      else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    })
  }

  // ------------ api of edit banner ---------------//
  editBanner(){
    let url = `admin/editBanner?bannerId=${this.pagekey}`
    let data = {
      'bannerTitle': this.editBannerForm.value.bannerTitle,
      'bannerImage': this.bannerImage
    }
    this.service.showSpinner()
    this.service.putApi(url,data,1).subscribe((res:any)=>{
      if(res.responseCode==200){
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        this.router.navigate(['/banner-management'])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    })
  }

}
