import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { dummy } from '../user-management/dummy';
declare var $:any
@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.css']
})
export class ReportManagementComponent implements OnInit {
  searchForm: FormGroup;
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  reportList: any = [];
  
  check:boolean
  
  // limit: any = 5;
  items = [];
  flip: boolean = false;
  isSearched: boolean = false;
  statusString: any;
  userId: any;
  status: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    // private datepipe: DatePipe
  ) {

  }

  ngOnInit(): void {
    // this.reportList=[...dummy]
    this.searchFormValidation();
    this.getAllReportList();
  }

  // search form validation
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      report: new FormControl("",Validators.required) 
    });
  }
  

  search() {
    if(this.searchForm.value.search) {
      this.getAllReportList();
    }
  }

  reset() {
    // if (this.searchForm.value.search || this.searchForm.value.report) {
      this.searchForm.reset();
      this.getAllReportList();
    // }
  }

  

  // get all report list
  getAllReportList() {
    let apiReqBody = {
      'search': this.searchForm.value.search,
      'page':this.currentPage,
      'limit':this.itemsPerPage
    };
    this.commonService.showSpinner();
    let url = `admin/listReports`;
    console.log(url);
    this.commonService.postApi(url, apiReqBody, 1).subscribe(
      (res:any) => {
        if (res.responseCode == 200) {
          this.reportList = res.result.docs;
          this.totalItems = res.result.total;
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast("Data not found.");
        }
      },(err:any)=>{
        this.totalItems=0
        this.reportList=[]
       }
    );
  }
  disable(value:boolean){
    this.check=value
    console.log(this.check);
    
  }
  pagination(event) {
    this.currentPage = event;
    this.getAllReportList();
  }


  viewReport(id) {
    this.router.navigate(["/report-view"], { queryParams: {Id: id} });
  }

  getUserId(id, status) {
    this.userId = id;
    this.status=status
    if (this.status == "AWAIT" || "RESPONDED") {
      this.ReportModalOpen()
    } 
  }

  blockUnblockPost() {
    let url = `admin/blockPostAndComment?reportId=${this.userId}`;
    // this.commonService.showSpinner();
    // console.log(this.searchForm.value.report);
    let apiReqData={
       "comment":this.searchForm.value.report
    }
    this.commonService.showSpinner()
    this.commonService.putApi(url,apiReqData, 1).subscribe(
      (res:any) => {
        if (res.responseCode == 200) {
          this.ReportModalClose()
          this.searchForm.reset();
          this.commonService.hideSpinner();
          this.commonService.successToast(res.responseMessage);
          this.getAllReportList();
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.responseMessage);
        }
      });
  }

  ReportModalOpen() {
    $('#ReportModal').modal('show')
  }
  ReportModalClose() {
    $('#ReportModal').modal('hide')
  }

  
}
