<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title"><i class="fa fa-question-circle" aria-hidden="true"></i>
                FAQ'S / View</h1>
        </div>
        
        <div class="content-section">
            <div class="order-view max-WT setting-page">
                <div class="mb40">
                    <div class="add-store-block input-style mb20 ">
                        <form>
                            <div class="form-group row align-items-baseline">
                                <div class="col-md-12 d-flex">
                                    <h4 class="col-md-3">Question</h4><span class="col-md-1">:</span>
                                    <p class="col-md-8" style="word-break: break-all;">{{(ques | titlecase )||'--'}}</p>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <div class="col-md-12 d-flex">
                                    <h4 class="col-md-3">Answer</h4><span class="col-md-1">:</span>
                                    <p class="col-md-8" style="word-break: break-all;"> {{(ans | titlecase)||'--' }}</p>
                                    <!-- <p class="mt30">{{(date | date : 'd/M/y') || '--'}}</p> -->
                                 
                                </div>
                            </div>
                        </form>
                        <div class="text-center mt40 mb40">
                            <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/faq" >Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
