import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
declare var $:any

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  adminProfile = new Subject<any>()

  // public baseUrl = "https://node-stromestreet.mobiloitte.com/api/v1/";
  public baseUrl = "https://backend.stormstreet.com/api/v1/"
  // public baseUrl = "http://172.16.2.19:3027/api/v1/";//local

  public websiteURL = "https://admin-angular-stromestreet.mobiloitte.org/";

  public userName = new Subject<any>();
  /**
   * @param loginData show user profile data if it's change or user login
   */
  public loginData = new Subject<any>();

  loginSub = new BehaviorSubject(``);
  loginObs = this.loginSub.asObservable();
  public profile =new Subject<any>()
  constructor(
    private spinner: NgxSpinnerService,
    public http: HttpClient,
    public router: Router,
    private toastr: ToastrService,
  ) { }


  changeLoginSub(msg) {
    this.loginSub.next(msg);
  }
  // ------------ post api -------------- //
  postApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
    }
    return this.http.post(this.baseUrl + endPoint, data, httpHeaders);
  }
  postFormDataApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          // "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          // "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
    }
    return this.http.post(this.baseUrl + endPoint, data, httpHeaders);
  }

  // -----------patch api --------------- //
  patchApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
    }
    return this.http.patch(this.baseUrl + endPoint, data, httpHeaders);
  }
  // -----------Put api---------------//
  putApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
    }
    return this.http.put(this.baseUrl + endPoint, data, httpHeaders);
  }
  // ----------- get api ---------------- //
  getApi(endPoint, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        }),
      };
    }
    return this.http.get(this.baseUrl + endPoint, httpHeaders);
  }
  // ----------- delete api ---------------- //
  deleteApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }), 'body': data
      }
    }
    return this.http.delete(this.baseUrl + endPoint, httpHeaders);
  }
  // ------------ get local file data ---------- //
  getLocalData(url) {
    return this.http.get(url);
  }

  // ------------ get third party api ---------- //
  getThirdPartyApi(url) {
    return this.http.get(url, { observe: "response" });
  }

  // ------------ user logged in or not
  public isLoggedIn() {
    return localStorage.getItem("token") !== null;
  }

  logout() {
    localStorage.removeItem("token");
    this.router.navigate(['/login'])
  }

  // ------------ Spinner ------------- //
  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  // ---------- toaster ----------------- //
  successToast(msg) {
    this.toastr.success(msg);
  }
  errorToast(msg) {
    this.toastr.error(msg);
  }
  infoToast(msg) {
    this.toastr.info(msg);
  }
  warningToast(msg) {
    this.toastr.warning(msg);
  }
  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }


  /** Function to restrict character */
  numberOnly(event): boolean {


    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
      // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  header(): string {
    return localStorage.getItem("userType")
  }

  public getTodayDate() {
    return new Date().toISOString().split('T')[0]
  }
  public showHead(): boolean {
    if (localStorage.getItem('isVerfied') == 'unveryfied') {
      return false
    }
    else {
      return true
    }
  }
  public getIPAddress() {
    return this.http.get("https://jsonip.com/?format=json");
  }
  getLocation(url) {
    return this.http.get(url)
  }
}

