<section class="vh-100" style="background-color: aliceblue;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <div class="col-md-6 col-lg-5 d-none d-md-block">
              <img accept="image/jpg,image/jpeg,image/png"
                src="/assets/img/strom_street.jpg"
                alt="login form"
                class="img-fluid" style="border-radius: 1rem 0 0 1rem;"
              />
            </div>
            <div class="col-md-6 col-lg-7 d-flex align-items-center">
              <div class="card-body p-4 p-lg-5 text-black">

                <form [formGroup]="loginForm">

                  <!-- <div class="align-items-center text-center mb-3 pb-1">
                    <i class="fas fa-cubes fa-2x me-3" style="color: #ff6219;"></i>
                    <span class="h1 fw-bold mb-0">LOGIN</span>
                  </div> -->

                  <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Sign into your account</h5>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example17" >Email address</label>
                    <input type="email" id="form2Example17" formControlName="email" class="form-control" maxlength="50" placeholder="Email address" (keypress)="commonService.preventSpace($event)" class="form-control form-control-lg" />
                    <div class="text-danger" *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty || loginForm.get('email').touched)" style="color: red; text-align: start">
                      <span *ngIf="loginForm.get('email').hasError('required')">*Email address is required.</span>
                      <span *ngIf="loginForm.get('email').hasError('pattern')">*Please enter valid email address.</span>
                    </div>
                   
                  </div>

                  <div class="form-outline mb-4">
                    <label class="form-label" for="form2Example27">Password</label>
                    <input [type]="newPassword ? 'password' : 'text'" id="form2Example27" maxlength="16" formControlName="password" class="form-control" placeholder="Password" (keypress)="commonService.preventSpace($event)" class="form-control form-control-lg" />
                    <div class="input-group-text">
                      <i (click)="newPassword = !newPassword" [class]="newPassword ? 'fa fa-eye' :'fa fa-eye-slash'"  aria-hidden="true" style="background-color: transparent;"></i>
                  </div>
                    <div class="text-danger" *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)" style="color: red; text-align: start">
                      <span *ngIf="loginForm.get('password').hasError('required')">*Password is required.</span>
                    </div>
                  </div>

                  <div class="pt-1 pb-1 mb-4">
                    <button class="btn btn-dark btn-lg btn-block" type="button" [disabled]="!loginForm.valid" (click)="onLogin()">Login</button>
                  </div>
                  <div style="display: flex;">
                    <div class="remember-text">
                      <label class="checkbox-design text-muted">
                        <input type="checkbox" formControlName="rememberMe"/><span></span>Remember me</label>
                    </div>
                    <a class="small text-muted" style="padding-top: 4px;margin-left: auto;"  routerLink="/forgetPassword">Forgot password?</a>
                  </div>
     
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


